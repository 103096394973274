import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TicketDTO } from 'src/app/DTO/TicketDTO';
import { TicketPurchaseDTO } from 'src/app/DTO/tickets/TicketPurchaseDTO';
import { ApiService } from 'src/app/services/api.service';
import { API_URL } from 'src/environments/environment';
import {CustomFieldDTO} from "../../../../../DTO/CustomFieldDTO";

@Injectable({
  providedIn: 'root'
})
export class TicketsService {

  constructor(private api: ApiService,
              private httpClient: HttpClient
  ) { }

  public getTicketById(communityId: number, ticketId: number): Observable<TicketDTO> {
    const url = `${API_URL}/v2/Communities/${communityId}/Modules/Tickets/${ticketId}`;
    return this.api.get(url);
  }

  public getAvailability(communityId: number, id: number): Observable<number> {
    return this.api.get(`${API_URL}/v2/Communities/${communityId}/Modules/Tickets/${id}/availability`);
  }

  public purchase(communityCode: string, id: number, userdata: any, details: any[] , customFields: CustomFieldDTO[]): Observable<any> {
    return this.httpClient.post(`${API_URL}/v2.1/Communities/${communityCode}/Modules/Tickets/${id}/purchase`, {
        details,
        userdata,
        customFields
    });
  }

  public checkPayment(communityCode: string, uuid: string): Observable<any> {
    return this.api.get(`${API_URL}/v2/Communities/${communityCode}/Modules/Tickets/my/${uuid}/checkPayment`);
  }

  public getMyTicket(communityCode: string, uuid: string): Observable<TicketPurchaseDTO> {
    return this.api.get(`${API_URL}/v2/Communities/${communityCode}/Modules/Tickets/my/${uuid}`);
  }

  public getMyTicketAnonymous(communityCode: string, uuid: string): Observable<TicketPurchaseDTO> {
    return this.api.get(`${API_URL}/v2/Communities/${communityCode}/Modules/Tickets/my-anonymous/${uuid}`);
  }
}
