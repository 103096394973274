<app-banner-pam>

</app-banner-pam>

<div class="body-wrapper"
     [class.iframe]="isIframe"
     [style]="'--corp-color-1: ' + community?.color1">
    <router-outlet></router-outlet>

</div>
