import {Component, OnInit} from '@angular/core';
import {Functions} from "../../helpers/Functions";
import {CommunityDTO} from "../../DTO/CommunityDTO";
import {CommunityService} from "../../services/community/community.service";
import {ApiService} from "../../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {ThemeService} from "../../services/theme/theme.service";
import {MetadataService} from "../../services/metadata.service";

@Component({
  selector: 'app-prior-appointment',
  templateUrl: './prior-appointment.component.html',
  styleUrl: './prior-appointment.component.scss'
})
export class PriorAppointmentComponent implements OnInit{
  communityName = "";
  community: CommunityDTO;
  constructor( private apiService: ApiService,
               private actRoute: ActivatedRoute,
               private translate: TranslateService,
               private communityService: CommunityService,
               private themeService: ThemeService,
               private router: Router,
               private metadataService: MetadataService) {
  }
  ngOnInit(): void {
    this.communityName = this.actRoute.snapshot.params['community'];
    this.communityService.Get().subscribe(res=>{
      this.community = res;
      if (this.community ) {
        this.metadataService.updateMetadata({
          title: Functions.decode( this.community.name) + " - " + this.translate.instant('PRIORAPPOINTMENT.title'),
        });
      }
    });
  }
  public newReservation(search?: boolean) {
    if(search){
      this.router.navigate([this.communityName+'/prior-appointment/detail/' ]);
    }else{
      this.router.navigate([this.communityName+'/prior-appointment/new/' ]);
    }
  }

}
