<form (ngSubmit)="onAddressEnter()">  
  <p-autoComplete #textInput  *ngIf="!showOnlyMap"
    field="place_name"
    [suggestions]="locationsFound"
    [placeholder]="'LOCATION_SELECTOR.FindStreet' | translate" 
    [(ngModel)]="address" 
    [ngModelOptions]="{standalone: true}"
    (completeMethod)="onComplete($event)"
    (onSelect)="onSelectedLocation($event)"
    [forceSelection]="true"    
    [emptyMessage]="'NoResults' | translate"
    [showEmptyMessage]="true"
    [dropdown]="true"
    styleClass="custom-autocomplete"
    class="custom-autocomplete"
    type="text"
    [autoHighlight]="true"
    [showClear]="true"
    [size]="100"
  ></p-autoComplete>
</form>

<div *ngIf="notes">
  <small [innerHTML]="notes"></small>
</div>

<div class="map-container">
  <img *ngIf="!showOnlyMap"
    [hidden]="!dragHelper"
    class="drag-helper"
    src="assets/img/finger.png">
  <div class="top-buttons" *ngIf="!showOnlyMap">
    <button 
    slot="icon-only"
    (click)="GetGPS()"
    [disabled]="isLocating">
      <i class="fa fa-location-arrow"></i>
    </button>
  </div>
  
  <div id="map" [class.loading]="loadingAddress"></div>

  <!--<div 
    *ngIf="!mapLoading"
    id="map"
    [class.loading]="loadingAddress"
    leaflet
    [leafletOptions]="mapOptions"
    (leafletMapReady)="onMapReady($event)"
  ></div>-->
</div>
