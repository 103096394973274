import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'daysHours'
})
export class DaysHoursPipe implements PipeTransform {

  constructor(private translate: TranslateService) {

  }

  transform(value: number): string {

    if (value > 24) {
      value = value / 24;
      return value.toFixed(0) + ` ${this.translate.instant('DaysLower')}`;
    } else {
      return value.toFixed(0) + ` ${this.translate.instant('HoursLower')}`;
    }
  }

}
