import { Inject, NgModule, Optional } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { NewsListComponent } from './pages/news/news.component';
import { NewsDetailComponent } from './pages/news/news-detail/news-detail.component';
import { EventsComponent } from './pages/events/events.component';
import { ContacteComponent } from './pages/contacte/contacte.component';
import {EventsDetailComponent} from "./pages/events/events-detail/events-detail.component";
import { ResultMessageComponent } from './pages/error/error.component';
import { ShareLinkComponent } from './pages/share-link/share-link.component';
import { REQ_URL } from './services/tokens/req-url';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { TwoColumnLayoutComponent } from './layouts/two-column-layout/two-column-layout.component';
import { ColumnLayoutComponent } from './layouts/column-layout/column-layout.component';
import { TicketQrComponent } from './pages/events/events-detail/payment-modal/tickets/ticket-qr/ticket-qr.component';
import {InscriptionsDetailComponent} from "./pages/inscriptions/inscriptions-detail/inscriptions-detail.component";
import {InscriptionsComponent} from "./pages/inscriptions/inscriptions.component";
import {PAMComponent} from "./pages/pam/pam.component";
import {PamLayoutComponent} from "./layouts/pam-layout/pam-layout.component";
import {PamActionComponent} from "./pages/pam/pam-action/pam-action.component";
import {PamAreaDetailComponent} from "./pages/pam/pam-area-detail/pam-area-detail.component";
import {PamTypeDetailComponent} from "./pages/pam/pam-type-detail/pam-type-detail.component";
import {PamOdsDetailComponent} from "./pages/pam/pam-ods-detail/pam-ods-detail.component";
import {PriorAppointmentComponent} from "./pages/prior-appointment/prior-appointment.component";
import {ReservationDetailComponent} from "./pages/prior-appointment/reservation-detail/reservation-detail.component";
import {NewReservationComponent} from "./pages/prior-appointment/new-reservation/new-reservation.component";

const routes: Routes = [
  {
    path : '',
    redirectTo: 'error',
    pathMatch: 'full'
  },
  {
    path : 'error',
    component : ResultMessageComponent,
    loadChildren: () => import('./pages/error/error.module').then(m => m.ResultMessageModule),
  },
  {
    path: 'success',
    component: ResultMessageComponent,
    loadChildren: () => import('./pages/error/error.module').then(m => m.ResultMessageModule),
  },
  {
    path: ':community/password-reset/:userId/:token',
    component: PasswordResetComponent,
    loadChildren:  () => import('./pages/password-reset/password-reset.module').then(m => m.PasswordResetModule),
  },
  {
    path: ':community',
    component: MainLayoutComponent,
    children: [
      {
        path: 'news',
        component: TwoColumnLayoutComponent,
        children: [
          {
            path: '',
            component: NewsListComponent,
          },
          {
            path: ':id',
            component: NewsDetailComponent,
          },
        ]
      },
      {
        path: 'events',
        component: ColumnLayoutComponent,
        children: [
          {
            path: '',
            component: EventsComponent,
          },
          {
            path: ':id',
            component: EventsDetailComponent,
          },
        ]
      },
      {
        path: 'inscriptions',
        component: ColumnLayoutComponent,
        children: [
          {
            path: '',
            component: InscriptionsComponent,
          },
          {
            path: ':id',
            component: InscriptionsDetailComponent,
          },
        ]
      },
      {
        path: 'PAM',
        component: PamLayoutComponent,
        children: [
          {
            path: '',
            component: PAMComponent,
          },{
            path: 'action/:id',
            component: PamActionComponent,
          },
          {
            path: 'area/:id',
            component: PamAreaDetailComponent,
          }
          ,{
            path: 'type/:id',
            component: PamTypeDetailComponent,
          }
          ,{
            path: 'ods/:id',
            component: PamOdsDetailComponent,
          }

        ]
      },
      {
        path: 'prior-appointment',
        component: ColumnLayoutComponent,
        children: [
          {
            path: '',
            component: PriorAppointmentComponent,
          },{
            path: 'detail',
            component: ReservationDetailComponent,
          },
          {
            path: 'new',
            component: NewReservationComponent,
          }

        ]
      },
      {
        path: 'incidents',
        loadChildren: () => import('./pages/incidents/incidents.module').then(m => m.IncidentsComponentModule)
      },
      {
        path: 'contact',
        component: ContacteComponent,
      },
    ]
  },
  {
    path: ':community/tickets/print/:uuid',
    component: TicketQrComponent
  }
];

const linkDomainRoutes: Routes = [
  {
    path : '**',
    component : ShareLinkComponent
  }
];



@NgModule({
  imports: [RouterModule.forRoot([], {
    paramsInheritanceStrategy: 'always',
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled',
})],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(
    private router: Router,
    @Optional() @Inject(REQ_URL) private reqUrl: string,
  ){

    if(!this.reqUrl){
      this.reqUrl = window.location.href;
    }
    var url = new URL(this.reqUrl);

    const isLinkSubdomain: boolean =
      url.hostname.startsWith('link') ||
      url.hostname.startsWith('share') ||
      this.reqUrl.includes('?share');

      if(isLinkSubdomain){
        this.router.resetConfig(linkDomainRoutes);
      } else {
        this.router.resetConfig(routes);
      }

  }
}
