import {Component, EventEmitter, OnInit} from '@angular/core';
import {Functions} from "../../../helpers/Functions";
import {ApiService} from "../../../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {CommunityService} from "../../../services/community/community.service";
import {ThemeService} from "../../../services/theme/theme.service";
import {MetadataService} from "../../../services/metadata.service";
import {CommunityDTO} from "../../../DTO/CommunityDTO";
import {ReservationDTO} from "../../../DTO/PriorAppointment/ReservationDTO";
import Swal from "sweetalert2";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-new-reservation',
  templateUrl: './new-reservation.component.html',
  styleUrl: './new-reservation.component.scss'
})
export class NewReservationComponent implements OnInit {
  isLoading = false;
  events: any[];
  stepNumber: number;
  communityName = "";
  community: CommunityDTO;
  reservation: ReservationDTO;
  public nextAttempt = new EventEmitter();

  constructor(private apiService: ApiService,
              private actRoute: ActivatedRoute,
              public activeModal: NgbActiveModal,
              private translate: TranslateService,
              private communityService: CommunityService,
              private metadataService: MetadataService,
              private router: Router,) {

  }

  ngOnInit(): void {
    this.isLoading = true;
    this.reservation = new ReservationDTO();
    this.communityName = this.actRoute.snapshot.params['community'];
    this.communityService.Get().subscribe(res => {
      this.community = res;
      if (this.community) {
        this.metadataService.updateMetadata({
          title: Functions.decode(this.community.name) + " - " + this.translate.instant('PRIORAPPOINTMENT.title'),
        });
      }
    });
    this.stepNumber = 1;
    this.events = [
      {status: '1', icon: 'fa-solid fa-1'},
      {status: '2', icon: 'fa-solid fa-2'},
      {status: '3', icon: 'fa-solid fa-3'},
    ];
    this.isLoading = false;
  }

  attemptNext() {
    this.nextAttempt.emit(true);
  }

  nextStep() {
    if (this.stepNumber == 2) {

      this.createReservation();
    }
    if (this.stepNumber < this.events.length && this.stepNumber !== 2) {
      this.stepNumber++;
    }
  }

  prevStep() {
    this.stepNumber--;
  }
  finishReservation(){
    this.router.navigate([this.communityName+'/prior-appointment/' ]);
  }

  createReservation() {
    Swal.fire({
      title: this.translate.instant('PRIORAPPOINTMENTCREATE.creatingReservationTitle'),
      text: this.translate.instant('PRIORAPPOINTMENTCREATE.creatingReservationText'),
      icon: 'info',
      showCancelButton: false,
      allowOutsideClick: false,
      didOpen: () => {

        Swal.showLoading();
        this.apiService.sendReservation(this.community.code, this.reservation).toPromise()
            .then((res) => {
              Swal.fire({
                title: this.translate.instant('GENERIC.good'),
                icon: 'success',
                text: this.translate.instant('PRIORAPPOINTMENTCREATE.reservationCreatedSuccess'),
                confirmButtonText: this.translate.instant('PRIORAPPOINTMENT.genericAccept')
              });
              this.stepNumber++;
              this.activeModal.dismiss('reload');
            })
            .catch(error => {
              Swal.fire({
                title: this.translate.instant('GENERIC.bad'),
                icon: 'error',
                html: this.translate.instant('PRIORAPPOINTMENTCREATE.reservationSendError'),
                confirmButtonText: this.translate.instant('Accept')
              });
            });
      }
    });
  }
}
