import { Component, Output, OnInit, EventEmitter, Input } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { UserDTO } from 'src/app/DTO/UserDTO';
import {CustomFieldDTO} from "../../../../../DTO/CustomFieldDTO";
import {DateTime} from "luxon";
import {CommunityService} from "../../../../../services/community/community.service";
import {CommunityDTO} from "../../../../../DTO/CommunityDTO";

@Component({
  selector: 'app-user-data',
  templateUrl: './user-data.component.html',
  styleUrls: ['./user-data.component.scss'],
})
export class UserDataComponent implements OnInit {

  @Input() fullFree: boolean;
  @Input() customFields: CustomFieldDTO[];

  @Input() submitAttempt = new EventEmitter();
  @Output() userEmitter = new EventEmitter();
  @Output() formValid = new EventEmitter<boolean>();

  public form: FormGroup;
  public submitAttempted = false;
  public community: CommunityDTO = new CommunityDTO();
  constructor(private _formBuilder: FormBuilder,
              private communityService: CommunityService,) {
  }

  ngOnInit(): void {
    this.setForm();
    this.submitAttempt.subscribe((x => this.submitAttempted = true));
    this.communityService.Get().subscribe(res=> {
      this.community = res;
    });
  }

  private setForm() {
    const mailReg = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      surname: new FormControl('', Validators.required),
      mail: new FormControl('', [Validators.required, Validators.pattern(mailReg)]),
      phone: new FormControl('', Validators.required),
      acceptance: new FormControl('', null),
      user: new FormControl('')
    })

    for (const customField of this.customFields) {
      this.form.addControl(customField.id.toString(), this._formBuilder.control(null));
    }


    this.form.valueChanges.subscribe((x)=> {
      var user = new UserDTO();
      user.name = this.form.get('name').value;
      user.mail = this.form.get('mail').value;
      user.surname = this.form.get('surname').value;
      user.phone = this.form.get('phone').value;
      var acceptance =  this.form.get('acceptance').value;

      if(acceptance)
      {
        user.acceptanceCommercial = DateTime.now().toJSDate();
      }else{
        user.acceptanceCommercial = null;
      }


      this.userEmitter.emit({
        'user-data': user
      })
      this.formValid.emit(this.form.valid);
    })
  }
}
