import { Injectable } from '@angular/core';
const SMALL_WIDTH_BREAKPOINT = 768;
@Injectable({
    providedIn: 'root'
})
export class ScreenSizeService {
    private mediaMatcher: MediaQueryList =
        matchMedia(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`);

    constructor(
    ) { }
    isScreenSmall(): boolean {
        return this.mediaMatcher.matches;
    }
}
