import {Component, OnInit} from '@angular/core';
import {ApiService} from "../../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CommunityService} from "../../services/community/community.service";
import {ThemeService} from "../../services/theme/theme.service";
import {CommunityDTO} from "../../DTO/CommunityDTO";

@Component({
  selector: 'app-pam-layout',
  templateUrl: './pam-layout.component.html',
  styleUrl: './pam-layout.component.scss'
})
export class PamLayoutComponent implements OnInit{
  isIframe = false;
  public community?: CommunityDTO;
  isLoading = true;
  public hideColumn: boolean;

  constructor(
      private apiService: ApiService,
      private router: Router,
      private actRoute: ActivatedRoute,
      private themeService: ThemeService) { }

  async ngOnInit() {
    let c = this.actRoute.snapshot.params['community'];

    this.isIframe = await this.themeService.getIsIframe();

    this.apiService.getCommunityByCode(c).subscribe(res => {
      if (res) {
        this.community = res;
      } else {
        this.router.navigateByUrl('/error');
      }
    });
  }
}
