import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private translate: TranslateService) { }

  async getLocation(showWarnings = true): Promise<GeolocationPosition> {
    return new Promise<GeolocationPosition>(async (resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position: GeolocationPosition) => {
            console.log('Coords', position);
            resolve(position);
          },
          async (error: GeolocationPositionError) => {
            if (showWarnings) this.presentNoPermissionsWarning();
            reject(error);
          }
        );
      } else {
        if (showWarnings) this.presentNoPermissionsWarning();
        reject();
      }
    });
  }

  async presentWarning(result: string) {
    Swal.fire({
      title: this.translate.instant('Oops'),
      text: result,
      icon: 'warning',
      confirmButtonText: this.translate.instant('Accept'),
    });  
  }

  async presentNoPermissionsWarning() {
    this.presentWarning(this.translate.instant('LOCATION.NoPermissions'));    
  }

}