<div class="prior-appointment-new-wrapper py-5">
    <div class="container">
        <h1 class="fw-600 mb-2">{{ 'PRIORAPPOINTMENT.request' | translate }}</h1>
        <h3 class="mb-5">{{ 'PRIORAPPOINTMENT.managementSystem' | translate }}</h3>
        <div class="row">
            <div class="col-12 col-md-6">
                <p class="fw-600 mb-3" *ngIf="stepNumber === 1" [style.color]="community?.color1">{{ 'PRIORAPPOINTMENT.stepService' | translate }}</p>
                <p class="fw-600 mb-3" *ngIf="stepNumber === 2" [style.color]="community?.color1">{{ 'PRIORAPPOINTMENT.stepPersonalData' | translate }}</p>
                <p class="fw-600 mb-3" *ngIf="stepNumber === 3" [style.color]="community?.color1">{{ 'PRIORAPPOINTMENT.stepConfirmation' | translate }}</p>
                <p-timeline [value]="events" layout="horizontal" align="top">
                    <ng-template pTemplate="marker" let-event>
                        <span class="icon-number border p-2"
                              [style.background-color]="event?.status <= stepNumber ? community?.color1 : ''"
                              [style.color]="event?.status <= stepNumber ? 'white' : ''">
                            <i [class]="event.icon"></i>
                        </span>
                    </ng-template>
                </p-timeline>
            </div>
        </div>
        <div class="steps my-4" *ngIf="!isLoading && community">
            <app-reservation-step1
                    *ngIf="stepNumber == 1"
                    [community]="community"
                    [reservation]="reservation"
                    [nextAttempt]="nextAttempt"
                    (formValid)="nextStep()">
            </app-reservation-step1>
            <app-reservation-step2
                    *ngIf="stepNumber == 2"
                    [community]="community"
                    [reservation]="reservation"
                    [nextAttempt]="nextAttempt"
                    (formValid)="nextStep()">
            </app-reservation-step2>
            <app-finish-reservation
                    *ngIf="stepNumber == 3"
                    [community]="community"
                    [reservation]="reservation"
                    [nextAttempt]="nextAttempt">
            </app-finish-reservation>
        </div>
        <div class="buttons d-flex align-items-center justify-content-between">
            <div>
                <button (click)="prevStep()" class="btn bg-white rounded py-2 px-4 me-4" *ngIf="stepNumber > 1 && stepNumber < 3">
                    <i class="fa fa-arrow-left"></i> {{ 'PRIORAPPOINTMENT.previous' | translate }}
                </button>
            </div>
            <div>
                <button (click)="attemptNext()" class="btn bg-white rounded py-2 px-4 me-4" *ngIf="stepNumber < 3">
                    {{ stepNumber == 2 ? ('PRIORAPPOINTMENT.confirm' | translate) : ('PRIORAPPOINTMENT.next' | translate) }} <i class="fa fa-arrow-right"></i>
                </button>
                <button (click)="finishReservation()" class="btn text-white rounded py-2 px-4 fw-500" [style.background-color]=" community?.color1 ? community?.color1 : 'black'" *ngIf="stepNumber === 3">
                    {{'PRIORAPPOINTMENT.finishReservation' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
