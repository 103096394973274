
<button
  [type]="buttonType"
  [disabled]="disabled || pendingChanges === false || isLoading"
  class="btn {{customClass}}"
  [class.saving]="isLoading || successfully"
  (click)="onClick()"
>
  <span class="text">
    <i *ngIf="icon" class="{{icon}} me-1"></i> {{ text }}
  </span>

  <div class="spinner-wrapper">
    <div *ngIf="!successfully" class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <span *ngIf="successfully">
      <i class="fa fa-check"></i>
    </span>
  </div>
</button>
