import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommunityDTO } from 'src/app/DTO/CommunityDTO';
import { CommunityModuleDTO } from 'src/app/DTO/CommunityModuleDTO';
import { ApiService } from 'src/app/services/api.service';
import { CommunityService } from 'src/app/services/community/community.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],  
})
export class HeaderComponent implements OnInit {

  @Input () selectedNumber: number = 1;
  @Input () community?: CommunityDTO;
  selected: number = 1;
  communityName: string = "";
  colorOfCommunity : string = "";

  modules: CommunityModuleDTO[];

  menuTabs: any[]= [];  
  
  public currentLang: string;
  public langs: any[] = [];

  constructor(private router: Router,
    private apiService: ApiService,    
    private communityService: CommunityService,
    private languageService: LanguageService
    ) {
  }

  ngOnInit(): void {    
    this.selected = this.selectedNumber;

    this.languageService.getCurrentLang().subscribe((res) => {
      this.currentLang = res;
    });
    this.languageService.getAvailableLanguages().subscribe((lngs) => {
      this.langs = lngs;
    });

    this.setNavLinks();
  }

  setNavLinks() {    
    this.communityService.setMenuTabs()
      .then((result) => {
        console.log(result);
        this.menuTabs = result;    
      })    
  }

  //Cambia la página de menu (se hace de esta manera para no instalar angular materials)
  // 1 == Actualitat
  // 2 == Contacte
  abrirResponsive(){
    this.apiService.emitNavChangeEvent(this.community.code);
  }

  changeLang(lang) {    
    this.languageService.setLanguage(lang, true);
  }
}
