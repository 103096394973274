
<div class="modal-body loading text-center pb-4" *ngIf="!customFields || isLoading">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

@if(customFields && !isLoading) {

  <div class="form-inscription">
    <form [formGroup]="formGroup" #form="ngForm">
      <div class="row">
        <div class="col-12 col-md-6" *ngFor="let field of customFields; let i = index;">
          <app-custom-field-input
            *ngIf="field"
            [customField]="field"
            (changeThePrice)="saveIncrement($event)"
            formControlName="{{field.id.toString()}}"
            [class.error]="formGroup.get(field.id.toString())?.invalid && form.submitted"
          ></app-custom-field-input>

          <div class="mb-3 text-danger">
            <div *ngIf="formGroup.get(field.id.toString())?.hasError('required') && form.submitted">
              {{ 'RequiredField' | translate }}
            </div>

            <div *ngIf="formGroup.get(field.id.toString())?.hasError('format') && form.submitted">
              {{ 'IncorrectFormat' | translate }}
            </div>
          </div>

        </div>


        <div *ngIf="recurrences.length > 1" class="col-12 col-md-6 d-flex flex-column mb-3 mb-md-0">
          <div>{{ 'INSCRIPTIONS.PaymentType' | translate }}</div>
          <p-dropdown [options]="recurrences"
                      [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="inscriptionSent.finalRecurrence"
                      optionValue="type"
                      [filter]="true"
                      (ngModelChange)="recalcPrice()">
            <ng-template let-selected pTemplate="selectedItem">
              <div class="flex align-items-center gap-2">
                <div>{{ ('INSCRIPTIONS.PAYMENT_RECURRENCES.' + selected.type) | translate }} <b *ngIf="selected.discount">-{{ selected.discount | number:'1.0-2'}}%</b></div>
              </div>
            </ng-template>
            <ng-template let-x pTemplate="item">
              <div class="flex align-items-center gap-2">
                <div>{{ ('INSCRIPTIONS.PAYMENT_RECURRENCES.' + x.type) | translate }} <b *ngIf="x.discount">-{{ x.discount | number:'1.0-2'}}%</b></div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <div *ngIf="bonifications.length > 0" class="col-12 col-md-6 d-flex flex-column">
          <div>{{ 'INSCRIPTIONS.Bonus' | translate }}</div>

          <p-dropdown [options]="bonifications"
                      [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="inscriptionSent.bonificationId"
                      optionValue="id"
                      [filter]="true"
                      [showClear]="true"
                      [placeholder]="'INSCRIPTIONS.BonusPlaceholder' | translate"
                      (ngModelChange)="recalcPrice()">
            <ng-template let-selected pTemplate="selectedItem">
              <div class="flex align-items-center gap-2">
                <div>{{ selected.name }} <b>-{{ selected.discount | number:'1.0-2'}}%</b></div>
              </div>
            </ng-template>
            <ng-template let-x pTemplate="item">
              <div class="flex align-items-center gap-2">
                <div>{{ x.name }}  <b>-{{ x.discount | number:'1.0-2'}}%</b></div>
              </div>
            </ng-template>
          </p-dropdown>

          <label class=" col-form-label font-w-600 mb-e mt-3" *ngIf="showTheDocument">
            {{ 'INSCRIPTIONS.BonusDocument' | translate }} <span class="required_asterisk">*</span>
          </label>

          <div *ngIf="showTheDocument">
            <app-file-uploader
              category="inscriptionSent"
              [communityNameInput]="communityCode"
              [maxFiles]="5"
              [type]="1"
              [(files)]="this.inscriptionSent.files"
              [class.invalid]="!documentValid"
              (filesChange)="getUploadedFile($event)"
            ></app-file-uploader>

          </div>
        </div>
      </div>

      <div class="rgpd mt-3">
        <small [innerHTML]="inscription.rgpd"></small>
      </div>
    </form>
  </div>


  <div class="prices">
    <div>
      @if (inscription.basePrice !== 0) {
        <app-price [price]="prices.price" [recurrence]="finalRecurrence" [priceWithoutDiscount]="prices.priceWithoutDiscount"/>

        <!-- @if(prices.firstPayment.fee > 0) {
          <div class="mt-2 enrollment">
            <span>
              {{ 'Fee' | translate }}:
              <strong class="ms-1">{{ prices.firstPayment.fee | currency: 'EUR' }}</strong>
            </span>
          </div>
        } -->
      } @else {
        <strong>{{ 'Free' | translate }}</strong>
      }

      @if (inscription.enrollmentFee) {
        <app-price [price]="prices.enrollment"/>
      }

      @if (prices.firstPayment.taxAmount > 0) {
        <div class="tax-included">
          ({{ prices.firstPayment.taxName }} {{ 'Included' | translate | lowercase }})
        </div>
      }

    </div>
    <button class=" buy-btn rounded" style="max-width: 200px" [style]="'--corp-color-1:' +corpColor+ ';'"
      [class.opacity]="formGroup.invalid || submitLoading" (click)="save()">
      @if(!submitLoading){
        {{ 'INSCRIPTIONS.Enroll' | translate }}
      } @else {
        <app-spinner size="s"></app-spinner>
      }
    </button>
  </div>
}


