import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ApiService} from "../../services/api.service";
import {ActivatedRoute} from "@angular/router";
import {MetadataService} from "../../services/metadata.service";
import { DateTime } from 'luxon';
import {EsdevenimentDTO} from "../../DTO/EsdevenimentDTO";
import {CommunityDTO} from "../../DTO/CommunityDTO";
import { Functions } from 'src/app/helpers/Functions';
import { TranslateService } from '@ngx-translate/core';
import { EventFilterDTO } from 'src/app/DTO/filters/EventFilterDTO';
import { OverlayPanel } from 'primeng/overlaypanel';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Paginator } from 'primeng/paginator';
import { StorageService } from 'src/app/services/storage.service';
import { CommunityService } from 'src/app/services/community/community.service';
import { ThemeService } from 'src/app/services/theme/theme.service';

export class StateSession {
  public static DATES_DROPDOWN = 'STATE.DATES_DROPDOWN';
  public static DATES_RANGE = 'STATE.DATES_RANGE';
}

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  @ViewChild('swiper1') swiper1: ElementRef;
  @ViewChild('singleDay') singleDay: OverlayPanel;
  @ViewChild('rangeDates') rangeDates: OverlayPanel;
  @ViewChild('targetEl') targetEl: ElementRef;
  @ViewChild('paginatorElement', { static: true }) paginatorElement: Paginator;

  public community: CommunityDTO;
  public communityName: string;

  public title: string;

  public esdevenimentsLoaded: boolean = false;

  public allEvents: EsdevenimentDTO[] = [];
  public featuredEvents: EsdevenimentDTO[] = [];
  public nextEvents: EsdevenimentDTO[] = [];
  public filteredEvents: EsdevenimentDTO[] = [];

  // Date dropdown
  public dateOptions: any[];
  public dateOptionSelected: any;

  public isOverlay = false;

  public dateRanges: string[] = [];

  // Date pickers
  public singleDateSelected: Date;
  public rangedDateSelected: Date [];

  public typesLoaded = false;
  public typesOptions: any[] = [];
  public selectedTypes: number[] = [];

  public priceOptions = [];
  public selectedPrice: any = null;
  public paginator = false;
  public page = 1;
  public collectionSize = 1;
  public faArrowRight = faArrowRight;
  public faArrowLeft = faArrowLeft;
  public first = 0;
  public rows = 9;
  public isIframe = false;
  public isCustom: boolean;

  private filters: EventFilterDTO = null;
  private overlayOptions = [4,5];
  private pageSize = 9;
  private isFree: boolean = null;
  private customCommunities = [
    'tortola-de-henares'
  ];

  constructor(
    private apiService: ApiService,
    private actRoute: ActivatedRoute,
    private metadataService: MetadataService,
    private translate: TranslateService,
    private communityService: CommunityService,
    private themeService: ThemeService
  ) { }

  async ngOnInit() {
    this.communityName = this.actRoute.snapshot.params['community'];

    this.isIframe = await this.themeService.getIsIframe();

    this.isCustom = this.customCommunities.includes(this.communityName);

    this.title = this.translate.instant('Events');
    this.communityService.GetModulesDict().subscribe((res) => {
      if (res && res["CALENDAR"]){
        this.title = res["CALENDAR"].name;
      }
    });

    this.init();
  }

  public pageChange(event) {
    this.first = event.first;
    this.rows = event.rows;
    this.esdevenimentsLoaded = false;
    this.filterEvents()
  }

  public getSelectedPrice(event) {
    this.getFilterData(event, 'price')
  }

  public getFilterData(event, type) {
    this.esdevenimentsLoaded = false;

    switch(type) {
      case 'category':
        this.selectedTypes = event;
        if (event == null) {
          this.filteredEvents = [];
        }
        break;
      case 'date':
        console.log('dates', event)
        this.dateRanges = event;
        break;
      case 'price':
        if(event == null) {
          this.selectedPrice = null;
          this.isFree = null;
        } else {
          this.selectedPrice = event;
          this.isFree = this.selectedPrice.value == 0;
        }
        break;
    }

    this.first = 0;
    this.filterEvents();
  }

  //#region DATES

  public getDateOption(event: any) {
    //console.log('event', event);

    StorageService.Session.set(StateSession.DATES_DROPDOWN, event?.value ?? null);

    this.dateRanges = [];

    if(event != null) {
      event = event.value;
      this.dateOptionSelected = event == null ? null : this.dateOptions.find(x => x.value == event);

      this.isOverlay = this.overlayOptions.includes(event);

      if (!this.isOverlay) {
        this.singleDay.hide();
        this.rangeDates.hide();
      }

      const today = DateTime.now().startOf('day');
      switch (event) {
        case 0:
          this.dateRanges = [today.toISO()];
          //console.log('today', this.dateRanges);
          break;
        case 1:
          const tomorrow = today.plus({ days: 1 });

          this.dateRanges = [tomorrow.toISO()];
          //console.log('tomorrow', this.dateRanges);
          break;
        case 2:
          const monday = today.startOf('week').toISO();
          const sunday = today.endOf('week').endOf('day').toISO();

          this.dateRanges = [monday, sunday]
          //console.log('this week', this.dateRanges);
          break;
        case 3:
          const firstDay = today.startOf('month').toISO();
          const lastDay = today.endOf('month').endOf('day').toISO();

          this.dateRanges = [firstDay, lastDay];
          //console.log('this month', this.dateRanges);
          break;
        case 4:
          // this.rangeDates.hide();
          this.dateRanges = [];
          this.singleDateSelected = null;
          this.singleDay.toggle(new MouseEvent('click'), this.targetEl.nativeElement)
          break;
        case 5:
          // this.singleDay.hide();
          this.dateRanges = [];
          this.rangedDateSelected = [];
          this.rangeDates.toggle(new MouseEvent('click'), this.targetEl.nativeElement)
          break;
       }

       // ISO
       StorageService.Session.set(StateSession.DATES_RANGE, this.dateRanges);
    }
    else {
      this.dateOptionSelected = null;
      this.dateRanges = [];
      this.isOverlay = false;

      StorageService.Session.set(StateSession.DATES_RANGE, []);
    }

    if(!this.isOverlay) {
      this.updateCurrentPage(1)
      this.filterEvents();
    }
  }

  public onSingleeShow(event) {
    console.log('singleSHown', event);
  }

  public onRangeShow(event) {
    console.log('rangeSHown', event);
  }

  public onSingleHide(event) {
    console.log('singleSHidden', event);
  }

  public onRangeHide(event) {
    console.log('rangeSHidden', event);
  }

  public getSingleDate(event) {
    console.log('singleDate', event);
    console.log('singleDate', this.singleDateSelected);

    const date = DateTime.fromJSDate(new Date(event));

    this.dateRanges = [date.toISO()];

    // ISO
    StorageService.Session.set(StateSession.DATES_RANGE, this.dateRanges);

    this.updateCurrentPage(1)
    this.filterEvents();
    this.singleDay.toggle(null, this.targetEl.nativeElement);
  }

  public getRangeDates(event) {
    console.log('rangeDates', event);
    console.log('rangeDates', this.rangedDateSelected);
    this.dateRanges = event;
    if(!event.includes(null)) {

      this.dateRanges = this.dateRanges.map((x) => {
        const date = DateTime.fromJSDate(new Date(x));
        return date.toISO();
      });

      // ISO
      StorageService.Session.set(StateSession.DATES_RANGE, this.dateRanges);

      this.updateCurrentPage(1)
      this.filterEvents();
      this.rangeDates.toggle(null, this.targetEl.nativeElement);
    }
  }

  //#endregion

  private filterEvents() {
    this.esdevenimentsLoaded = false;

    this.filters = {
      categories: this.selectedTypes ?? [],
      dates: this.dateRanges ?? [],
      free: this.isFree,
      top: this.pageSize,
      skip: this.first
    } as EventFilterDTO;

    this.apiService.getEventsFiltered(this.community.code, this.filters)
      .subscribe((result) => {
        this.countEvents();
        this.allEvents = result;
        this.featuredEvents = result.filter(x => x.featured);
        this.nextEvents = result.filter(x => !x.featured);
        setTimeout(() => this.getSlidesPerView());
        this.esdevenimentsLoaded = true;
      });
  }

  private getEventsCategories() {
    this.apiService.getEventsCategories(this.community.id)
      .subscribe((result) => {
        this.typesOptions = result;
        this.typesLoaded = true;
      })
  }

  private countEvents() {
    this.apiService.countEventsFiltered(this.communityName, this.filters)
      .subscribe((res) => {
        if(res) {
          this.collectionSize = res;
        }
      })
  }

  private init() {
    this.esdevenimentsLoaded = false;
    this.apiService.getCommunityByCode(this.communityName).subscribe(responseData => {
      this.community = responseData;

      if (responseData) {
        this.metadataService.updateMetadata({
          title: Functions.decode(responseData.name) + " - " + this.title,
          description: this.translate.instant('NEWS.NewsFrom') + Functions.decode(responseData.name)
        });
      }

      this.firstLoad()
        .then((result) => {

          this.getEventsCategories();
        });
    })
  }

  private firstLoad(): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {

      this.priceOptions = [
        { value: 0, label: this.translate.instant('Free') },
        { value: 1, label: this.translate.instant('NotFree') }
      ];
      this.dateOptions = [
        { value: 0, label: this.translate.instant('DATE_OPTIONS.Today') },
        { value: 1, label: this.translate.instant('DATE_OPTIONS.Tomorrow') },
        { value: 2, label: this.translate.instant('DATE_OPTIONS.ThisWeek') },
        { value: 3, label: this.translate.instant('DATE_OPTIONS.ThisMonth') },
        { value: 4, label: this.translate.instant('Date') },
        { value: 5, label: this.translate.instant('DATE_OPTIONS.SelectDates') }
      ]

      let dateOptionSelected = StorageService.Session.get(StateSession.DATES_DROPDOWN);
      if (dateOptionSelected !== null){
        this.dateOptionSelected = this.dateOptions.find(x => x.value == dateOptionSelected);
        this.isOverlay = this.overlayOptions.includes(this.dateOptionSelected.value);

        // ISO
        this.dateRanges = StorageService.Session.get(StateSession.DATES_RANGE);

        // DATES
        this.rangedDateSelected = StorageService.Session.get(StateSession.DATES_RANGE).map(x => DateTime.fromISO(x).toJSDate());
        this.singleDateSelected = this.rangedDateSelected[0];

        //this.updateCurrentPage(1)
        //this.filterEvents();
      }

      this.filterEvents();

      resolve(true)
    });
  }

  private getSlidesPerView() {
    const swiperEl = document.querySelector('swiper-container');

    const swiperParams = {
      breakpoints: {
        576: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2
        },
        1200: {
          slidesPerView: 3
        }
      },
      on: {
        init() {
          console.log(swiperParams)
        },
      },
      spaceBetween: 25
    };

    Object.assign(swiperEl, swiperParams);

    swiperEl.initialize();
  }

  private updateCurrentPage(currentPage: number): void {
    this.first = 0;
    setTimeout(() => this.paginatorElement.changePage(0));
  }
}
