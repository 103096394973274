import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'datestring'
})
export class DatestringPipe implements PipeTransform {

  transform(dateStart: DateTime, dateEnd?: DateTime, showTime: boolean = true, short: boolean = false): any {
    if(dateEnd) {
      if(dateStart.hasSame(dateEnd, 'day')) {
        return `${this.formatDate(dateStart, true)} ${this.formatHour(dateEnd)} h`;
      } else {
        const now = DateTime.now();

        const showYear = !dateStart.hasSame(dateEnd, 'year') && dateStart.hasSame(now, 'year');

        const showMonth = showTime || !dateStart.hasSame(dateEnd, 'month');

        const date1 = this.formatDate(dateStart, showTime, showYear, showMonth, short);
        const date2 = this.formatDate(dateEnd, showTime, showYear, true, short);

        return `${date1} - ${date2}`
      }
    } else {
      return this.formatDate(dateStart, showTime);
    }
  }

  private formatDate(date: DateTime, showTime: boolean, showYear?: boolean, showMonth?: boolean, short? : boolean): string {

    const format = this.getFormat(showYear, showMonth, short);
    let dateFormatted = date.toFormat(format);

    if(showTime) {
       dateFormatted += ' ' + `${this.formatHour(date)} h`;
    }

    return dateFormatted;
  }

  private formatHour(time: DateTime): string {
    let format = 'H';

    if(time.minute !== 0) {
      format += ':mm';
    }

    return time.toFormat(format);
  }

  private getFormat(showYear: boolean = true, showMonth: boolean = true, short?: boolean): string {
    let format = 'd';

    if(showMonth) {
      if(!short) {
        format += ' LLLL'
      } else {
        format += ' LLL';
      }
    }

    if(showYear) {
      format += ' yyyy';
    }

    return format;
  }
}
