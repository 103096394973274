import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ApiService} from "../../services/api.service";
import {InscriptionCategoryDTO, InscriptionsDTO} from "../../DTO/InscriptionsDTO";
import {CommunityDTO} from "../../DTO/CommunityDTO";
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { InscriptionFilterDTO } from 'src/app/DTO/filters/InscriptionFilterDTO';
import { ThemeService } from 'src/app/services/theme/theme.service';

@Component({
  selector: 'app-inscriptions',
  templateUrl: './inscriptions.component.html',
  styleUrl: './inscriptions.component.scss'
})
export class InscriptionsComponent implements OnInit {
  inscriptions: InscriptionsDTO[];
  community?: CommunityDTO;
  communityName: string = "";
  public defaultImg: string;
  public isLoading = true;
  public page = 1;
  public collectionSize = 1;
  public faArrowRight = faArrowRight;
  public faArrowLeft = faArrowLeft;
  public first = 0;
  public rows = 9;
  public filtersLoading = true;
  public categoriesOptions: InscriptionCategoryDTO[] = [];
  public categoriesSelected: number[] = [];
  public filters: InscriptionFilterDTO = new InscriptionFilterDTO();
  public isIframe = false;

  private pageSize = 9;

  constructor(
    private actRoute: ActivatedRoute,
    private apiService: ApiService,
    private themeService: ThemeService
  ) {
    this.communityName = this.actRoute.snapshot.params['community'];
  }

  async ngOnInit() {
    this.isIframe = await this.themeService.getIsIframe();

    this.init();
  }

  public filterInscriptions(newFilter?: boolean) {
    if(newFilter) {
      this.first = 0;
      this.page = 1;
    }

    this.isLoading = true;

    this.filters = {
      skip: this.first,
      top: this.pageSize,
      categories: this.categoriesSelected,
    }

    this.apiService.getInscriptionsFiltered(
      this.communityName,
      this.filters)
      .subscribe(response => {
        this.countInscriptions();
        this.inscriptions = response;
        this.isLoading = false;
      })
  }

  public pageChange(event) {
    this.first = event.first;
    this.rows = event.rows;
    this.filters.skip = this.first;
    this.filterInscriptions()
  }

  private countInscriptions() {
    this.apiService.countInscriptionsFiltered(
      this.communityName,
      this.filters
    )
    .subscribe(total => this.collectionSize = total)
  }

  private getCommunity() {
    this.apiService.getCommunityByCode(this.communityName).subscribe(responseData => {
      this.community = responseData;

      this.getInscriptionsCategories();
    })
  }

  private getInscriptionsCategories() {
    this.apiService.getInscriptionCategories(this.community.id)
      .subscribe(response => {
        this.categoriesOptions = response;
        this.filtersLoading = false;
      })
  }

  private init() {
    this.getCommunity();

    this.filters = {
      skip: this.first,
      top: this.pageSize,
      categories: []
    }

    this.filterInscriptions();


    // this.apiService.getInscriptions(this.communityName)
    //   .subscribe(responseData => {

    //     if (responseData) {
    //       this.inscriptions = responseData;
    //       this.isLoading = false;
    //     }
    //   });
  }
}
