import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ImageUrlPipe } from 'src/app/pipes/image-url.pipe';
import { LocalizedDatePipe } from './localized-date.pipe';
import { DaysHoursPipe } from './dayshours.pipe';
import {FileUrlPipe} from "./file-url.pipe";
import { DatestringPipe } from './datestring.pipe';


@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        ImageUrlPipe,
        LocalizedDatePipe,
        DaysHoursPipe,
        FileUrlPipe,
        DatestringPipe
    ],
    declarations: [
        ImageUrlPipe,
        LocalizedDatePipe,
        DaysHoursPipe,
        FileUrlPipe,
        DatestringPipe
    ]
})
export class PipesModule { }
