import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommunityDTO } from 'src/app/DTO/CommunityDTO';
import { ApiService } from 'src/app/services/api.service';
import { ThemeService } from 'src/app/services/theme/theme.service';

@Component({
  selector: 'app-two-column-layout',
  templateUrl: './two-column-layout.component.html',
  styleUrls: ['./two-column-layout.component.scss']
})
export class TwoColumnLayoutComponent {

  isIframe = false;
  public community?: CommunityDTO;
  isLoading = true;
  public hideColumn: boolean;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private actRoute: ActivatedRoute,
    private themeService: ThemeService) { }

  async ngOnInit() {
    let c = this.actRoute.snapshot.params['community'];

    this.isIframe = await this.themeService.getIsIframe();

    this.apiService.getCommunityByCode(c).subscribe(res => {
      if (res) {
        this.community = res;
      } else {
        this.router.navigateByUrl('/error');
      }
    });
  }
}
