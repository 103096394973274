export class UserLogged {
  Active: string;
  id: number;
  mail: string;
  username: string;
  name: string;
  surname: string;
  dni: string;
  photo: string;
  roles: string;
  provider: string;
  isMailConfirmed: boolean;
  isActive: boolean;
  isLocked: boolean;
  NumTowns: number;
  accessToAllModules?: boolean;
}
export class ScheduleHome {
  activeConsultations_Count: number;
  pendingAlerts_Count: number;
  pendingProposals_Count: number;
  scheduledNotifications_Count: number;
  citizensSatisfaction_Percent: number;

  pendingNews_count: number;
  announcements_count: number;
  calendarCount: number;
  mailboxCount: number;

  totalUsers: number;
}
export class Role {
  description: string;
  id: number;
  name: string;
  type: string;
  roleName: string;
}
export class LoginResult {
  jwt: string;
  user: UserLogged = new UserLogged();

}
export class Towns {
  Name: string;
  id: number;
  Image: Image = new Image();
}
export class Image {
  name: string;
  url: string;
}
export class Cordenate {
  lat: number;
  lng: number;
}
export class Proposal {
  creator_Name: string; // Obsolete
  creator_Image: string; // Obsolete
  creatorName: string;
  creatorImage: string;
  id: number;
  title: string;
  description: string;
  html: string;
  image: string;
  images: string [] = [];
  url: string;
  location: number;
  locationLat: number;
  locationLng: number;
  statusId: number;
  statusName: string;
  isLoaded = true;
  isImgInvalid = false;
  votes_Objective: number;
  votes_Balance: number;
  votes_Positive: number;
  votes_Negative: number;
  votes_Total: number;
  numFollowing: number;
  numComments: number;
  createdAt: string;

  categoryName: string;
  categoryImage: string;
  categoryBackgroundGradientFrom: string;
  status_AdminMessage:string;
}
export class New {
  ShortDesc: string;
  Title: string;
}
export class User {
  dni: string;
  id: number;
  image: string;
  isActive: boolean;
  isLocked: boolean;
  isMailConfirmed: boolean;
  locationLat: number;
  locationLng: number;
  mail: string;
  name: string;
  numComments: number;
  numFavourites: number;
  numIncidents: number;
  numProposals: number;
  numTowns: number;
  phone: string;
  phonePrefix: string;
  surname: string;
  username: string;
  userGroupName: string;
  dniValidated: boolean;
  lang: string;
  createdAt: Date;
  lastLogin: Date;
  tags: string [] = [];
  roles: string[] = [];
  userGroupId: number;

  userType: string;
  accessToAllModules: boolean;
  modules: any[] = [];

  //helpers
  communityId: number;
}

export class NotificationDTO {
   id: number;
   title: string;
   message: string;
   preTitle: string;
   bigTitle: string;
   body: string;
   adminCustomMessage: string;
   categoryName: string;
   categoryColor: string;
   createdAt: Date
}

export class DeviceDTO {
  registrationId: string;
  model: string;
  platform: string;
  deviceName: string;
  deviceOperatingSystem: string;
  deviceOsVersion: string;
  deviceManufacturer: string;
  deviceWebViewVersion: string;
  version: string;
  appName: string;
  appId: string;
  appBuild: string;
}

export class AlertTable {
  data: Date;
  estat: string;
  usuari: string;
  title: string;
  number: number;
}
export class Status {
  id: number;
  name: string;
  description: string;
}
export class ModalConfirmation {
  title: string;
  description: string;
  status: number;
}
export class MyCityParams {
 id: number;
 name: string;
 image: string;
 color: string;
 minVoteThreshHold: number;
 active: boolean;
 numUsers: number;
}
export class MyLogoImgTown {
  image: string;
  name: string;
  id:number;

  public code: string;
  public province: string;

  public locationLat: number;
  public locationLng: number;
  public locationString: string;
  public mail: string;

  public osm_id: number;

  public dniValidation: boolean;
  public dniResidenceCheck: boolean;
  public locationCheck: boolean;

  public communityType: CommunityType;

  public appTitle: string;
  public color1: string;
  public color2: string;
  public colorPrimary: string;
}
export class TwitterTag {
  Key: string;
  Value: number;
  color: string;
  animation: string;
  text_size: number;
}
export enum CommunityType {
  Town = 0,
  Association = 1,
}

export class SendNotificationToZone {
 zoneType: number;
 notification: Notication = new Notication();
 schedule: string;
 customZone: CustomZone = new CustomZone();
 townID: number;
 popUpDesde: Date;
 popUpHasta: Date;
}

export class Notication {
  id: number;
  createdAt: string;
  creatorId: number;
  title: string;
  pushTitle: string;
  message: string;
  pushMessage: string;
  image: string;
  townId: number;
  recipientsReaded: number;
  recipientsTotal: number;
  body: number;
  categoryId?: number;
  files: any[];
  reactionAverage: number;
  totalReactions: number;
}

export class Announcement {
  id: number;
  type: number;
  active: boolean;
  title: string;
  description: string;
  image: string;
  imagePopUp: string;
  start: string;
  end: string;
  body: string;

  status: number;
  files: any[];

  totalReactions:number;
  reactionAverage:number;
}

export class Alertes {
  id: number;
  categoryName: string;
  categoryImage: string;
  categoryColor: string;
  categoryBackgroundGradientFrom: string;
  categoryBackgroundGradientTo: string;
  name: string;
  description: string;
  images: string [] = [];
  locationLat: number;
  locationLng: number;
  statusId: number;
  statusName: string;
  statusColor: string;
  categoryId: number;
  categoryInternalId: number;
  categoryInternalName: number;
  categoryInternalBackgroundGradientFrom: string;
  townId: number;
  createdAt: Date;
  creator_Name: string;
  creator_Photo: string;
  show_alert = true;
  opacity = 1;
  creator_Phone: string;
  creator_Mail: string;
  status_AdminMessage: string;

}

export class Circle {
  center: LatLng = new LatLng();
  radius: number;
}

export class LatLng {
  lat: number;
  lng: number;
}

export class CustomZone {
  customZoneType: number;
  circle: Circle = null;
  path: LatLng [] = [];
}

export class GeoJsonAdress {
 city: string;
 municipality: string;
 state: string;
 postcode: string;
 country: string;
 country_code: string;
}

export class Cordinate {
  lat: number;
  lng: number;
}
export class GeoJsonMap {
  type: string;
  coordinates: Cordinate [] = [];
}
export class GeoJsonCity {

  place_id: number;
  lat: number;
  lon: number;
  display_name: string;
  address: GeoJsonAdress;
  GeoJsonMap: GeoJsonMap;
}

export class FilterProposals {
  backgroundGradientFrom: string;
  backgroundGradientTo: string;
  description: string;
  icon: string;
  id: number;
  image: string;
  name: string;

}


export class ActivitiTable {
  creator_Image: string;
  creator_Name: string;
  id: number;
  statusId: number;
  text: string;
  title: string;
  type: number;
  when: string;
  number: number;
}
export class NotificationCategory {
  id: number;
  name: string;
  description: string;
  color: string;
  active: boolean;
}
export class MailDTO {
  id: number;
  TO: string;
  CC: string;
  CCO: string;
  html: string;
  Subject: string;
  CreatedAT: string;

}

