import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-status-color',
  standalone: true,
  imports: [
    CommonModule,
    NgbTooltipModule],
  templateUrl: './status-color.component.html',
  styleUrl: './status-color.component.scss'
})
export class StatusColorComponent implements OnInit{
  @Input()
  percent: number;
  public tooltip: string = "";
  public class: string;
  constructor() {
  }
  ngOnInit(): void {
    if(this.percent === 0){
      this.class = 'red';
    }
    if(this.percent < 100 && this.percent > 0){
      this.class = 'yellow';
    }
    if(this.percent === 100){
      this.class = 'green';
    }
    this.tooltip = this.percent.toFixed(2) + '%';
  }
}
