<div class="prior-appointment-wrapper py-5">
    <div class="container">
        <h1 class="fw-600 mb-2">{{ 'PRIORAPPOINTMENT.page_title' | translate }}</h1>
        <h3 class="mb-4">{{ 'PRIORAPPOINTMENT.page_subtitle' | translate }}</h3>
        <div class="row">
            <div class="d-flex">
                <button class="btn bg-white rounded p-3 me-4" (click)="newReservation()">
                    <i class="fa fa-plus-circle me-2"></i><span>{{ 'PRIORAPPOINTMENT.button_new' | translate }}</span>
                </button>

                <button class="btn bg-white rounded p-3 me-4" (click)="newReservation(true)">
                    <i class="fa fa-search me-2"></i><span>{{ 'PRIORAPPOINTMENT.button_view' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
</div>
