import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommunityDTO } from "../../../../DTO/CommunityDTO";
import { ReservationDTO } from "../../../../DTO/PriorAppointment/ReservationDTO";
import {ThemeService} from "../../../../services/theme/theme.service";

@Component({
  selector: 'app-reservation-step2',
  templateUrl: './reservation-step2.component.html',
  styleUrls: ['./reservation-step2.component.scss']
})
export class ReservationStep2Component implements OnInit {
  @Input() nextAttempt = new EventEmitter();
  @Output() formValid = new EventEmitter<boolean>();
  @Input() public reservation: ReservationDTO;
  @Input() public community: CommunityDTO;

  form: FormGroup;
  documentTypes = [
    { label: 'CIF', value: 0 },
    { label: 'NIE', value: 1 },
    { label: 'DNI', value: 2 }
  ];

  constructor(private fb: FormBuilder,
              public _themeService: ThemeService) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  private initializeForm(): void {
    this.form = this.fb.group({
      name: ['name', Validators.required],
      surname: ['surname', Validators.required],
      phone: ['phone', [Validators.required]],
      email: ['email', [Validators.required, Validators.email]],
      documentType: ['documentType', Validators.required],
      documentNumber: [
        'documentNumber',
        [
          Validators.required,
          Validators.pattern(
              /^(?:(?:[XYZxyz][0-9]{7}[A-Za-z])|(?:[A-Za-z]\d{7}[0-9A-Ja-j])|(?:\d{8}[A-Za-z]))$/
          )
        ]
      ],
      observations: ['observations'],
      acceptTractament: [false, Validators.requiredTrue]
    });
    this.nextAttempt.subscribe(() => this.validateForm());
    this.form.valueChanges.subscribe(() => {

    });
  }

  validateForm(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.reservation.acceptTractament = new Date();
      this.formValid.emit(true);
    }
  }
}
