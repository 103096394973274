import {Component, OnInit} from '@angular/core';
import {AreaDTO} from "../../../DTO/PAM/AreaDTO";
import {ApiService} from "../../../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {CommunityService} from "../../../services/community/community.service";
import {ThemeService} from "../../../services/theme/theme.service";
import {CommunityDTO} from "../../../DTO/CommunityDTO";
import {ActionDTO} from "../../../DTO/PAM/ActionDTO";
import {MetadataService} from "../../../services/metadata.service";
import {Functions} from "../../../helpers/Functions";

@Component({
  selector: 'app-pam-area-detail',
  templateUrl: './pam-area-detail.component.html',
  styleUrl: './pam-area-detail.component.scss'
})
export class PamAreaDetailComponent implements OnInit{
  area: AreaDTO;
  loading = false;
  actions: ActionDTO[];
  id:number;
  communityName = "";
  community: CommunityDTO;
  activeIndexActions: number;

  constructor(private apiService: ApiService,
              private actRoute: ActivatedRoute,
              private translate: TranslateService,
              private communityService: CommunityService,
              private themeService: ThemeService,
              private router: Router,
              private metadataService: MetadataService) {
  }
  ngOnInit(): void {
    this.loading = true;
    this.communityName = this.actRoute.snapshot.params['community'];
    this.id = Number(this.actRoute.snapshot.paramMap.get('id'));
    this.communityService.Get().subscribe(res=>{
      this.community = res;
      if (this.community ) {
        this.metadataService.updateMetadata({
          title: Functions.decode( this.community.name) + " - " + this.translate.instant('PAM.titleTab'),
        });
      }
    });
    this.apiService.getAreaDetail(this.communityName , this.id).subscribe((res: AreaDTO) => {
      this.area = res;

    });

    this.apiService.getActionsByArea(this.communityName , this.id).subscribe((res: ActionDTO[]) => {
      this.actions = res;
      this.loading = false;
    });
  }
  activeIndexChangeActions(index: any) {
    this.activeIndexActions = this.activeIndexActions === index ? -1 : index;
  }
  goDetail(id: number , event: any){
    event.preventDefault();
    event.stopPropagation();
    this.router.navigate([this.communityName+'/PAM/action/' + id ] , {state: {url:'area/' + this.id}});
  }
  getColorWithOpacity(color: string, opacity: number): string {
    if (color.startsWith('#')) {
      const r = parseInt(color.slice(1, 3), 16);
      const g = parseInt(color.slice(3, 5), 16);
      const b = parseInt(color.slice(5, 7), 16);
      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    }
    if (color.startsWith('rgb')) {
      return color.replace('rgb', 'rgba').replace(')', `, ${opacity})`);
    }
    return color;
  }
  goBack(): void {
    this.router.navigate([this.communityName+ '/PAM'] , { fragment: 'area' });
  }

}
