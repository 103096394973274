<div class="d-flex align-items-center">

  <!-- Display original price if discount applies -->
  @if (priceWithoutDiscount) {
    <div class="discount-line me-2">
      <strong>{{ (priceWithoutDiscount.showIncludedFee ? priceWithoutDiscount.totalWithFee : priceWithoutDiscount.total) | currency: 'EUR':'symbol':'1.0-2' }}</strong>
    </div>
  }
  
  @if (isEnrollment) {
    <span class="enrollment">
      <strong>{{ (price.showIncludedFee ? price.totalWithFee : price.total) | currency: 'EUR':'symbol':'1.0-2' }}</strong> {{ 'Enrollment' | translate | lowercase }}
    </span>
  } @else {
    <strong>
      {{ (price.showIncludedFee ? price.totalWithFee : price.total) | currency: 'EUR':'symbol':'1.0-2' }}
    </strong>
  }

  <!-- Handle recurring price label if recurrence exists -->
  @if (recurrence && recurrence !== RecurrencesTypes.OneTime) {
    <span class="recurrence ms-1"> / {{ ('INSCRIPTIONS.PAYMENT_RECURRENCES_AUX' + '.' + recurrence) | translate | lowercase }}</span>
  }
  
</div>
