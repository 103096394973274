
        <h2 *ngIf="!isIframe" class="text-xl mb-3 font-weight-bolder">{{ title }}</h2>

        <div class="newsContent" *ngIf="!newsLoaded">
            <ngx-skeleton-loader count="5" [theme]="{
                'height.px': 250,
                background: '#d0d0d0'
              }" animation="pulse"></ngx-skeleton-loader>
        </div>

        <app-empty-state *ngIf="newsLoaded && news.length == 0" icon="fa fa-newspaper-o" [text]="'NEWS.No_News' | translate"></app-empty-state>

        <div class="newsContent" *ngIf="newsLoaded">
            <a *ngFor="let new of news; let i = index" [routerLink]="[new.slug]" queryParamsHandling="merge">

                <div *ngIf="i===0" id="listitem{{i}}" class="first-image mr-0 mb-2 border shadow-material">
                  <img class="img" [src]="new.image | imageUrl" />
                  <div class="text">
                    <div class="date font-weight-light">{{new.publishDate | timeago}}</div>
                    <h2>{{new.title}}</h2>
                    <p class="d-xl-flex d-lg-flex d-md-flex d-sm-none d-none" [innerHTML]="new.summary"></p>
                    <div class="buttons d-xl-block d-lg-block d-md-block d-sm-none d-none">
                      <button class="readMoreButton text-m font-weight-lighter"><b>{{ 'Read_More' | translate }}</b></button>
                    </div>
                  </div>
                </div>

                <div *ngIf="i!==0" class="listNew mr-0 mb-2 border shadow-material">
                    <img class="img" [src]="new.image | imageUrl" />
                    <div class="text">
                        <div class="date font-weight-light">{{new.publishDate | timeago}}</div>
                        <h2>{{new.title}}</h2>
                        <p class="d-xl-flex d-lg-flex d-md-flex d-sm-none d-none" [innerHTML]="new.summary"></p>
                        <div class="buttons d-xl-block d-lg-block d-md-block d-sm-none d-none">
                            <button class="readMoreButton text-m font-weight-lighter"><b>{{ 'Read_More' | translate }}</b></button>
                        </div>
                    </div>
                </div>
            </a>

            <div *ngIf="paginator" class="paginator-wrapper">
                <ngb-pagination
                    [collectionSize]="collectionSize"
                    [(page)]="page"
                    [maxSize]="3"
                    [rotate]="true"
                    [boundaryLinks]="false"
                    (pageChange)="pageChange()"
                >
                    <ng-template ngbPaginationPrevious>
                        <fa-icon [icon]="faArrowLeft"></fa-icon>
                    </ng-template>
                    <ng-template ngbPaginationNext>
                        <fa-icon [icon]="faArrowRight"></fa-icon>
                    </ng-template>
                </ngb-pagination>
            </div>
        </div>
