import { Pipe, PipeTransform } from '@angular/core';
import {environment} from '../../environments/environment';

@Pipe({
  name: 'fileUrl'
})
export class FileUrlPipe implements PipeTransform {

  transform(value: string, type?: string): string {
    if (!value) {
      return value;
    }
    if (value.startsWith('http')) {
      return value;
    } else if (value.startsWith('/')) {
      return environment.storageURL + value;
    } else {
      return environment.storageURL + `/${type}/` +  value;
    }
  }

}
