<div>

  <div #sectionSubscribe>
    <div class="backButton mb-4">
      <h3 class="text-l font-weight-bolder" *ngIf="community != undefined" [routerLink]="[ '/',community.code , 'inscriptions']" queryParamsHandling="merge"><fa-icon [icon]="faArrowLeft"></fa-icon> {{ 'GoToList' | translate }}</h3>
    </div>
    <div *ngIf="isLoading">
      <div>
        <p-skeleton styleClass="w-100" width="10rem" height="20rem"></p-skeleton>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-md-8">
          <p-skeleton styleClass="w-100" width="10rem"></p-skeleton>
          <p-skeleton styleClass="w-75 mt-3" width="10rem"></p-skeleton>
          <p-skeleton styleClass="w-75 mt-2" width="10rem"></p-skeleton>
          <p-skeleton styleClass="w-100 mt-3"></p-skeleton>
          <p-skeleton styleClass="w-100 mt-1"></p-skeleton>
          <p-skeleton styleClass="w-100 mt-1"></p-skeleton>
          <p-skeleton styleClass="w-100 mt-1"></p-skeleton>
          <p-skeleton styleClass="w-100 mt-1"></p-skeleton>
        </div>
        <div class="col-12 col-md-4">
          <p-skeleton styleClass="w-100" height="5rem"></p-skeleton>
          <p-skeleton styleClass="w-25 mt-2"></p-skeleton>
          <p-skeleton styleClass="w-50 mt-1"></p-skeleton>
        </div>
      </div>
    </div>
    <div *ngIf="!isLoading" class="detail-wrapper">
      <div class="image-box">
        <img [src]="inscription.images[0]?.path ?? defaultImg | imageUrl">
      </div>
      <div class="container-fluid white-row p-4">
        <div class="row">
          <div class="col-12 col-md-8 pe-md-5">
            <div>
              <span class="category">{{ inscription.categoryName }}</span>
              <h1 class="ml-0 pl-0 title">{{inscription.name}}</h1>
            </div>
            <div class="row">

              <hr>
              <p [innerHTML]="inscription.body"></p>

              <ng-container>
                <div class="col-12" *ngFor="let value of customFieldValues">
                  <app-custom-field-display [fieldvalue]="value"> </app-custom-field-display>
                </div>
                <div *ngIf="inscription.files.length">
                  <b class="mb-2">{{'Fitxers_adjunts' | translate}}</b>
                  <div class="col-12 mb-3 mt-1">
                    <app-file-uploader
                      category="inscriptions"
                      [viewOnly]=" true"
                      [maxFiles]="5"
                      [type]="1"
                      [(files)]="inscription.files"
                    ></app-file-uploader>
                  </div>
                </div>
              </ng-container>
              <hr>
              <b class="mb-2">{{'dataContact' | translate}}</b>
              <div [innerHTML]="inscription.contactInformation"> </div>


          </div>
          </div>
          <div class="col-12 col-md-4 shadow-material p-4 " *ngIf="!isMobile">
            <div>
              <div>
                <div>
                  <div *ngIf="openStatus == OpenStatuses.FUTURE">
                    <div class="dot bg-gray" ></div>
                    <b class="ms-3 text-gray">{{ 'INSCRIPTIONS.Period_future' | translate }}</b>
                  </div>
                  <div *ngIf="openStatus == OpenStatuses.OPEN">
                    <div class="dot bg-success" ></div>
                    <b class="ms-3 text-success">{{ 'INSCRIPTIONS.Period_open' | translate }}</b>
                  </div>
                  <div *ngIf="openStatus == OpenStatuses.PAST">
                    <div class="dot bg-danger" ></div>
                    <b class="ms-3 text-danger">{{ 'INSCRIPTIONS.Period_past' | translate }}</b>
                  </div>
                </div>
                <div class="mt-1 mb-3" [class.text-success]="openStatus == OpenStatuses.OPEN"
                     [class.text-danger]="openStatus == OpenStatuses.PAST"
                     [class.text-gray]="openStatus == OpenStatuses.FUTURE">
                    <span>
                      {{ dateOpen | datestring : dateClose : true}}
                    </span>
                </div>

                <div  class="mt-2 d-flex align-items-center" *ngIf="inscription.capacity">
                  <div class="spacing">
                    <img src="assets/icons/users.svg" >
                  </div>
                  <b>{{ 'INSCRIPTIONS.Capacity' | translate }}</b>
                </div>
                <div class="d-flex mb-3" *ngIf="inscription.capacity">
                  <div class="spacing"></div>
                  <span *ngIf="inscription.capacity">{{inscription.capacity}} {{ 'INSCRIPTIONS.CapacityPlaces' | translate | lowercase }} </span>
                  <span *ngIf="!inscription.capacity">{{'INSCRIPTIONS.unlimitedPlaces' | translate}} </span>
                </div>

                <div  class="mt-2 d-flex align-items-center" *ngIf="inscription.enrollmentFee">
                  <div class="spacing">
                    <img src="assets/icons/money.svg" >
                  </div>
                  <b>{{ 'INSCRIPTIONS.RegistrationFee' | translate }}:</b>
                </div>
                <div class="d-flex mb-3" *ngIf="inscription.enrollmentFee">
                  <div class="spacing"></div>
                  {{ inscription.enrollmentFee | currency: 'EUR':'symbol':'1.0-2' }}
                </div>
                <div  class="mt-2 d-flex align-items-center">
                  <div class="spacing">
                    <img src="assets/icons/euro.svg" >
                  </div>
                  <b>{{ 'Price' | translate }}</b>
                </div>
                <div class="d-flex mb-3">
                  <div class="spacing"></div>
                  <span>{{ inscription.basePrice | currency: 'EUR':'symbol':'1.0-2' }}</span>
                  <span *ngIf="inscription.defaultRecurrence != 'OneTime'" class="recurrence"> / {{
                    ('INSCRIPTIONS.PAYMENT_RECURRENCES.' + inscription.defaultRecurrence) | translate | lowercase}}</span>
                </div>
              </div>



              <div class="mt-2" *ngIf="!isMobile">

                <ng-container *ngIf="openStatus == OpenStatuses.OPEN">
                  <div *ngIf="inscription.capacity - inscription.nInscrits < 10 && inscription.capacity - inscription.nInscrits > 1"
                    class="mb-2 text-red fw-bold">
                    {{ 'INSCRIPTIONS.OnlyXPlacesLeft' | translate: { n: inscription.capacity - inscription.nInscrits } }}
                  </div>
                  <div *ngIf="inscription.capacity - inscription.nInscrits < 10 && inscription.capacity - inscription.nInscrits == 1"
                    class="mb-2 text-red fw-bold">
                    {{ 'INSCRIPTIONS.Only1PlaceLeft' | translate }}
                  </div>
                </ng-container>

                <button *ngIf="openStatus == OpenStatuses.FUTURE && !inscription.isAttended" class="buy-btn disabled rounded fw-bold" [disabled]="true">
                  {{ 'INSCRIPTIONS.OpensInX' | translate: { x : (inscription.dateOpen | relativeTime)} }}
                </button>

                <button *ngIf="openStatus == OpenStatuses.PAST && !inscription.isAttended" class="buy-btn disabled rounded fw-bold" [disabled]="true">
                  {{ 'INSCRIPTIONS.InscriptionClosed' | translate }}
                </button>

                <button *ngIf="openStatus == OpenStatuses.OPEN && !isFull && !inscription.isAttended" class="buy-btn rounded rounded fw-bold"  (click)="openInscriptionForm()">
                  {{ 'INSCRIPTIONS.Enroll' | translate }}
                </button>

                <button *ngIf="openStatus == OpenStatuses.OPEN && isFull && !inscription.isAttended" class="buy-btn disabled rounded fw-bold" [disabled]="true">
                  {{ 'INSCRIPTIONS.InscriptionFull' | translate }}
                </button>
                <button *ngIf="inscription.isAttended" class="buy-btn disabled rounded" [disabled]="true">
                  {{ 'INSCRIPTIONS.InscriptionAttended' | translate }}
                </button>

                <button *ngIf="isFull && this.inscription.waitingList && !inscription.isAttended" class="buy-btn outline mt-2 rounded fw-bold" (click)="joinToWaitingList()">
                  {{ 'INSCRIPTIONS.JoinWaitingList' | translate }}
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="mt-2 fixed-btn p-4" *ngIf="isMobile">
        <div class="d-flex mb-2 align-items-center">

          <div *ngIf="inscription.capacity" class="me-2">
            <div  class="mt-2 d-flex align-items-center">
              <div class="spacing">
                <img src="assets/icons/users.svg" >
              </div>
              <span >{{inscription.capacity}} {{ 'INSCRIPTIONS.CapacityPlaces' | translate | lowercase }} </span>
            </div>

          </div>

          <div *ngIf="inscription.enrollmentFee" class="me-2">
            <div  class="mt-2 d-flex align-items-center">
              <div class="spacing">
                <img src="assets/icons/money.svg" >
              </div>
              {{ inscription.enrollmentFee | currency: 'EUR':'symbol':'1.0-2' }}
            </div>

          </div>

          <div>
            <div  class="mt-2 d-flex align-items-center">
              <div class="spacing">
                <img src="assets/icons/euro.svg" >
              </div>
              <div class="d-flex flex-column">
              <span>{{ inscription.basePrice | currency: 'EUR':'symbol':'1.0-2' }}</span>
              <span *ngIf="inscription.defaultRecurrence != 'OneTime'" class="recurrence"> / {{
                ('INSCRIPTIONS.PAYMENT_RECURRENCES.' + inscription.defaultRecurrence) | translate | lowercase}}</span>
              </div>
            </div>
          </div>

        </div>
        <ng-container *ngIf="openStatus == OpenStatuses.OPEN">
          <div *ngIf="inscription.capacity - inscription.nInscrits < 10 && inscription.capacity - inscription.nInscrits > 1"
               class="mb-3 text-red">
            {{ 'INSCRIPTIONS.OnlyXPlacesLeft' | translate: { n: inscription.capacity - inscription.nInscrits } }}
          </div>
          <div *ngIf="inscription.capacity - inscription.nInscrits < 10 && inscription.capacity - inscription.nInscrits == 1"
               class="mb-3 text-red">
            {{ 'INSCRIPTIONS.Only1PlaceLeft' | translate }}
          </div>
        </ng-container>

        <button *ngIf="openStatus == OpenStatuses.FUTURE && !inscription.isAttended" class="buy-btn disabled rounded" [disabled]="true">
          {{ 'INSCRIPTIONS.OpensInX' | translate: { x : (inscription.dateOpen | relativeTime)} }}
        </button>

        <button *ngIf="openStatus == OpenStatuses.PAST && !inscription.isAttended" class="buy-btn disabled rounded" [disabled]="true">
          {{ 'INSCRIPTIONS.InscriptionClosed' | translate }}
        </button>
        <button *ngIf="inscription.isAttended" class="buy-btn disabled rounded" [disabled]="true">
          {{ 'INSCRIPTIONS.InscriptionAttended' | translate }}
        </button>

        <button *ngIf="openStatus == OpenStatuses.OPEN && !isFull && !inscription.isAttended" class="buy-btn rounded"  (click)="openInscriptionForm()">
          {{ 'INSCRIPTIONS.Enroll' | translate }}
        </button>

        <button *ngIf="openStatus == OpenStatuses.OPEN && isFull && !inscription.isAttended" class="buy-btn disabled rounded" [disabled]="true">
          {{ 'INSCRIPTIONS.InscriptionFull' | translate }}
        </button>

        <button *ngIf="isFull && !inscription.isAttended && this.inscription.waitingList" class="buy-btn outline mt-2 rounded" (click)="joinToWaitingList()">
          {{ 'INSCRIPTIONS.JoinWaitingList' | translate }}
        </button>
      </div>
      <div>

      </div>
    </div>

</div>
</div>
