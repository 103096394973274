<div class="ods-detail container pb-5">
    <a class="text-black my-4 cursor-pointer d-block" (click)="goBack()"><i class="fa fa-arrow-left me-1"></i> {{ 'Back' | translate }}</a>
    <div class="w-100 p-3 card-ods cursor-pointer mb-5 rounded shadow" *ngIf="ods"
         [style.background-color]="getColorWithOpacity(ods.color , 0.2)"
         [style.color]="ods.color">
        <div class="d-flex justify-content-between align-items-center">
            <div class=" d-flex align-items-center">
                <img style="max-width:100px" class="rounded" src="assets/icons/ODSPAM/{{ods.image}}">
                <span class=" ms-3 fw-600">{{ods.name}}</span>
            </div>


        </div>
        <p class="mb-0 fw-600 number">{{ods.progress.toFixed(2)}}%</p>
        <p-progressBar class="w-100" [value]="ods.progress"  [style]="{'--progress-color': ods.color}"></p-progressBar>

        <div class="d-flex justify-content-between align-items-center mt-3">
            <span class="status px-2 py-1 rounded text-white" [ngClass]="ods.progress === 0 ? 'red' : ods.progress === 100 ? 'green' : 'yellow'">
                                    <span *ngIf="ods.progress === 0"> {{'PAM.notStarted' | translate}}</span>
                                    <span *ngIf="ods.progress === 100"> {{'PAM.completed' | translate}}</span>
                                    <span *ngIf="ods.progress > 0 && ods.progress < 100"> {{'PAM.inProgress' | translate}}</span>
                                </span>
        </div>
    </div>
    <p-skeleton  *ngIf="!ods" styleClass="mb-4"  height="200px"></p-skeleton>

    <h3>{{'PAM.actions' | translate}}</h3>
    <div *ngIf="!actions?.length && !loading">
        <app-empty-state [text]="'PAM.emptyODS' | translate"></app-empty-state>
    </div>
    <p-skeleton  *ngIf="loading" styleClass="mb-4"  height="130px"></p-skeleton>
    <p-skeleton  *ngIf="loading" styleClass="mb-4"  height="130px"></p-skeleton>
    <p-skeleton  *ngIf="loading" styleClass="mb-4"  height="130px"></p-skeleton>
    <p-accordion (activeIndexChange)="activeIndexChangeActions($event)" [activeIndex]="activeIndexActions">
        <p-accordionTab *ngFor="let action of actions; let j = index">
            <ng-template pTemplate="header">
            <div class="p-4 row-actions w-100" >
                <div class="row align-items-center justify-content-between">
                                            <span class="d-flex d-md-none col-12 col-md-2 mt-2 mt-md-0 mb-2" style="font-weight: 600; font-size: 14px"
                                                  [style.color]="ods.color ? ods.color : 'black'">
                                                <span *ngIf="action?.actuations?.length !== 0" >{{action?.actuations?.length}}</span>
                                                <span class="ms-1" *ngIf="action?.actuations?.length == 1"> actuació</span>
                                                <span class="ms-1 text-lowercase" *ngIf="action?.actuations?.length > 1"> {{'PAM.actuations' | translate}}</span>
                                                <span  *ngIf="action?.actuations?.length === 0"> Aquesta acció no te actuacions</span>
                                            </span>
                    <div class="col-12 col-md-6 d-flex align-items-center">
                        <i class="fa p-2 cursor-pointer text-white me-3 icon-fix d-none d-md-block" [ngClass]="activeIndexActions === j ? 'fa-minus' : 'fa-plus'"
                           [style.background-color]="ods.color ? ods.color : 'black'">
                        </i>
                        <p class="mb-0 fw-500">{{action.name}}</p>
                    </div>
                    <span class="d-none d-md-flex col-12 col-md-2 mt-2 mt-md-0" style="font-weight: 600" [style.color]="ods.color ? ods.color : 'black'">
                                               <span *ngIf="action?.actuations?.length !== 0" >{{action?.actuations?.length}}</span>
                                                <span class="ms-1" *ngIf="action?.actuations?.length == 1"> actuació</span>
                                                <span class="ms-1 text-lowercase" *ngIf="action?.actuations?.length > 1"> {{'PAM.actuations' | translate}}</span>
                                                <span  *ngIf="action?.actuations?.length === 0"> Aquesta acció no te actuacions</span>
                                            </span>
                    <div class="d-flex col-12 col-md-2 my-3 my-md-0">
                        <span class="me-2">{{action.progress.toFixed(2)}}%</span>
                        <p-progressBar [style]="{'--progress-color': ods.color}" [value]="action.progress"></p-progressBar>
                    </div>
                    <div class="d-flex col-12 col-md-2 justify-content-between justify-content-md-end link-detail">
                        <a class="text-underline text-dark" (click)="goDetail(action.id , $event);">
                            <i class="fa fa-eye me-2"></i>
                            <span>+ info</span>
                        </a>
                        <i class="fa p-2 cursor-pointer text-white icon-fix d-block d-md-none" [ngClass]="activeIndexActions === j ? 'fa-minus' : 'fa-plus'"
                           [style.background-color]="ods.color ? ods.color : 'black'"
                           [style.display]="action?.actuations?.length === 0 ? 'none!important':''">
                        </i>
                    </div>
                </div>
            </div>
            </ng-template>
            <ng-template pTemplate="content">
                <p-table [value]="action.actuations" class="table-actuations">
                    <ng-template pTemplate="header">
                        <tr>
                            <th [style.color]="ods.color ? ods.color : 'black'">{{'PAM.status' | translate}}</th>
                            <th [style.color]="ods.color ? ods.color : 'black'">{{'PAM.actuations' | translate}}</th>
                            <th [style.color]="ods.color ? ods.color : 'black'">{{'PAM.date' | translate}}</th>
                            <th [style.color]="ods.color ? ods.color : 'black'">{{'PAM.progress' | translate}}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-actuation>
                        <tr>
                            <td><app-status-color [percent]="actuation.percent"></app-status-color></td>
                            <td class="fw-500">{{ actuation.name }}</td>
                            <td>{{ actuation.date | date: 'dd/MM/yyyy' }}</td>
                            <td>{{ actuation.percent.toFixed(2) }}%</td>
                        </tr>
                    </ng-template>
                </p-table>
            </ng-template>
        </p-accordionTab>
    </p-accordion>
</div>
