import { CommunityType } from "./ModalData";

export class CommunityDTO {
    id: number = 0;
    code: string = "";
    name?: string;
    image?: string;
    colorPrimary: string;
    color1: string;
    color2: string;
    phone?: string;
    mail?: string;
    locationString?: string;

    logoNegative?: string;
    logoHorizontal?: string;
    logoHorizontalNegative?: string;

    appName?: string;
    appTitle?: string;

    customAppId?: string;
    customAppIdApple?: string;

    locationLat: number;
    locationLng: number;
    communityType: CommunityType;
    parameters: any[];
  }
