import {Component, Input, OnInit} from '@angular/core';
import {PipesModule} from "../../pipes/pipes.module";
import {CommonModule} from "@angular/common";
import {HttpClient} from "@angular/common/http";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
@Component({
  selector: 'app-convert-svg',
  standalone: true,
    imports: [
        CommonModule,
        PipesModule
    ],
  templateUrl: './convert-svg.component.html',
  styleUrl: './convert-svg.component.scss'
})
export class ConvertSvgComponent implements OnInit{

    @Input() height = "30px";
    @Input() width = "30px";
    @Input() path: string;
    @Input() currentColor: string = 'currentColor';
    svgContent: SafeHtml  = '';
    constructor(private http: HttpClient,
                private sanitizer: DomSanitizer) {
    }
    ngOnInit(): void {
        this.loadSvg();
    }
    loadSvg(): void {
        if (this.isSvg()) {

            this.http.get(this.path, { responseType: 'text' }).subscribe(
                (data: string) => {

                    const modifiedSvg = data
                        .replace(/<svg /, `<svg width="100%" height="100%" fill="${this.currentColor}" `)
                        .replace(/currentColor/g, this.currentColor);
                    this.svgContent = this.sanitizer.bypassSecurityTrustHtml(modifiedSvg);

                },
                error => {
                    console.error('Error al cargar el SVG', error);
                }
            );
        }
    }
    isSvg(): boolean {
        return this.path.endsWith('.svg');
    }

    isImage(): boolean {
        const imageExtensions = ['.png', '.jpg', '.jpeg', '.gif'];
        return imageExtensions.some(ext => this.path.endsWith(ext));
    }

}
