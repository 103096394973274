import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from "../../../../services/api.service";
import { TranslateService } from "@ngx-translate/core";
import { CommunityService } from "../../../../services/community/community.service";
import { CenterDTO } from "../../../../DTO/PriorAppointment/CenterDTO";
import { CommunityDTO } from "../../../../DTO/CommunityDTO";
import { ServiceDTO } from "../../../../DTO/PriorAppointment/ServiceDTO";
import { ReservationDTO } from "../../../../DTO/PriorAppointment/ReservationDTO";
import {
  PeriodScheduleDTO,
  ScheduleDayOfWeekDTO,
  ScheduleHourDTO
} from "../../../../DTO/PriorAppointment/PeriodsAndHoursDto";
import { DateTime } from "luxon";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {ThemeService} from "../../../../services/theme/theme.service";

@Component({
  selector: 'app-reservation-step1',
  templateUrl: './reservation-step1.component.html',
  styleUrl: './reservation-step1.component.scss'
})
export class ReservationStep1Component implements OnInit {

  public isReloadingInfo = false;
  public centerDTOs: CenterDTO[] = [];
  public servicesDTOs: ServiceDTO[] = [];
  public scheduleHours: ScheduleHourDTO[] = [];
  public activeService: ServiceDTO;
  public periodHours: number;
  public activeCenter: CenterDTO;
  public selectedDay: Date;
  public minDate: Date;
  public disabledDates: Date[] = [];
  public availableTimes: any[] = [];
  public types: any[] = [];
  public loadingCenters = false;
  public loadingServices = false;
  public activeTime: string;
  public listOfReservedDays: string[];
  public maxDate: Date;
  public loadType = false;
  @Input() nextAttempt = new EventEmitter();
  @Output() formValid = new EventEmitter<boolean>();
  @Input() public community: CommunityDTO;
  @Input() public reservation: ReservationDTO;
  form: FormGroup;

  constructor(private apiService: ApiService,
              private translate: TranslateService,
              private communityService: CommunityService,
              public _themeService: ThemeService) {
  }

  ngOnInit(): void {
    if (this.reservation?.centerId) {
      this.isReloadingInfo = true;
    }

    this.initializeForm();
    this.fetchCenters();
    this.nextAttempt.subscribe(() => this.validateForm());
  }

  private initializeForm(): void {
    const today = new Date();
    this.minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);

    this.form = new FormGroup({
      center: new FormControl(null, Validators.required),
      service: new FormControl(null, Validators.required),
      type: new FormControl(null, Validators.required),
      date: new FormControl(null, Validators.required),
      time: new FormControl(null, Validators.required),
    });

    this.form.valueChanges.subscribe(() => {
      Object.values(this.form.controls).forEach(control => control.markAsUntouched());
    });
  }

  private fetchCenters(): void {

    this.loadingCenters = true;
    this.apiService.getCenters(this.community?.code).subscribe((res: CenterDTO[]) => {
      this.centerDTOs = res;
      this.loadingCenters = false;
      if (this.reservation?.centerId) {
        this.form.patchValue({type: this.reservation.type});
        this.activeCenter = this.centerDTOs.find(x => x.id === this.reservation.centerId);
        if (this.activeCenter) {


          this.form.patchValue({center: this.activeCenter.id});
          this.fetchServicesForCenter();
        }
      }
    });
  }

  private fetchServicesForCenter(): void {
    if (!this.activeCenter) return;

    this.loadingServices = true;
    this.apiService.getServicesByCenterId(this.community?.code, this.activeCenter.id).subscribe((res: ServiceDTO[]) => {
      this.servicesDTOs = res;
      this.loadingServices = false;

      if (this.reservation?.serviceId) {
        this.activeService = this.servicesDTOs.find(x => x.id === this.reservation.serviceId);

        if (this.activeService) {
          this.apiService.getDatesReserved(this.community?.code, this.activeService.id).subscribe((res: string[]) => {
            this.listOfReservedDays = res;
            this.form.patchValue({service: this.activeService});
            this.periodHours = this.activeService.slot;
            this.selectedDay = DateTime.fromISO(this.reservation.startTime).toJSDate();
            this.showHours();
            this.selectTime(this.formatTime(this.selectedDay));
            this.types = this.getServiceTypes(this.activeService.type);
            this.isReloadingInfo = false;
            if(this.activeService.schedule == 0){
              this.groupDaysOfWeekByPeriodArray(this.activeService);
              this.generateDisabledDates(true);
            }else{
              this.generateDisabledDates();
            }
          });
        }
      }
    });
  }

  setCenter(center: CenterDTO): void {
    this.activeCenter = center;
    this.form.patchValue({center: center.id});
    this.reservation.centerId = center.id;
    this.reservation.centerName = center.name;
    this.servicesDTOs = [];
    this.cleanForm(1);
    this.fetchServicesForCenter();
  }

  selectedService(service?: ServiceDTO): void {
    this.activeService = service;
    this.loadType = true;
    if (!this.activeService) return;
    this.apiService.getDatesReserved(this.community?.code, this.activeService.id).subscribe((res: string[]) => {
      this.listOfReservedDays = res;

      if(this.activeService.schedule == 0){
        this.groupDaysOfWeekByPeriodArray(this.activeService);
        this.generateDisabledDates(true)
      }else{
        this.generateDisabledDates();
      }

      this.types = this.getServiceTypes(this.activeService.type);
      this.availableTimes = [];
      this.periodHours = this.activeService.slot;
      this.form.patchValue({service: this.activeService});
      this.cleanForm(2);
      this.loadType = false;
    });
  }

  selectType(): void {
    this.form.patchValue({type: this.reservation.type});
  }

  showHours(bool?: boolean): void {
    if (!this.activeService || !this.selectedDay) return;
    this.cleanForm(3);
    this.scheduleHours = [];
    this.availableTimes = [];
    this.activeTime = '';
    this.form.patchValue({date: this.selectedDay});
    let periodSchdules : any;
    if(this.activeService.schedule === 0){
      this.groupDaysOfWeekByPeriodArray(this.activeService)
      periodSchdules = this.activeService.periodSchedulesObject
    }else{
      periodSchdules = this.activeCenter.periodSchedules
    }


    const date = new Date(this.selectedDay);
    const period = this.getPeriodForDate(new Date(date.setHours(0, 0, 0, 0)),  periodSchdules);
    if (period) {
      const day = this.selectedDay.getDay();
      const hours = period.scheduleDayOfWeeks.find(x => x.dayOfWeek === day)?.scheduleHours;
      if (hours) {
        this.scheduleHours = hours;
        this.availableTimes = this.generateAvailableTimes(hours);
      }
    }
  }

  private generateAvailableTimes(hours: ScheduleHourDTO[]): string[] {

    const times: any[] = [];
    const reservedTimesForDay = this.listOfReservedDays
        .filter(reserved =>
            DateTime.fromISO(reserved).hasSame(DateTime.fromJSDate(this.selectedDay), 'day')
        )
        .map(reserved => this.formatTime(DateTime.fromISO(reserved).toJSDate()));

    const maxCapacity = this.activeService?.capacity || 1;

    hours.forEach(schedule => {
      let openTime = new Date(this.selectedDay);
      openTime.setHours(schedule.openHour, schedule.openMinuts, 0);

      const closeTime = new Date(this.selectedDay);
      closeTime.setHours(schedule.finishHour, schedule.finishMinuts, 0);

      while (openTime < closeTime) {
        const formattedTime = this.formatTime(openTime);

        const reservedCount = reservedTimesForDay.filter(reservedTime => reservedTime === formattedTime).length;

        const isReserved = reservedCount >= maxCapacity;


        times.push({ time: formattedTime, reserved: isReserved });

        openTime.setMinutes(openTime.getMinutes() + this.periodHours);
      }
    });
    return times.sort((a, b) => {
      const [aHours, aMinutes] = a.time.split(':').map(Number);
      const [bHours, bMinutes] = b.time.split(':').map(Number);
      return aHours * 60 + aMinutes - (bHours * 60 + bMinutes);
    });
  }


  private getPeriodForDate(date: Date, periodSchedules: PeriodScheduleDTO[]): PeriodScheduleDTO | null {
    return periodSchedules.find(period => {
      const start = DateTime.fromISO(period.start).toJSDate();
      const end = DateTime.fromISO(period.end).toJSDate();
      return date >= start && date <= end;
    }) || null;
  }

  private getServiceTypes(type: number): any[] {
    if (type === 0) return [{name: 'Presencial', value: 0}];
    if (type === 1) return [{name: 'Online', value: 1}];
    if (type === 2) return [
      {name: 'Presencial', value: 0},
      {name: 'Online', value: 1}
    ];
    return [];
  }

  selectTime(time: string): void {
    this.activeTime = time;
    this.form.patchValue({time});

    const [hours, minutes] = time.split(':').map(Number);
    const startTimeDate = new Date(this.selectedDay);
    startTimeDate.setHours(hours, minutes, 0);

    const endTimeDate = new Date(startTimeDate);
    endTimeDate.setMinutes(startTimeDate.getMinutes() + this.periodHours);

    this.reservation.startTime = DateTime.fromJSDate(startTimeDate).toISO();
    this.reservation.endTime = DateTime.fromJSDate(endTimeDate).toISO();
  }

  validateForm(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.reservation.centerId = this.activeCenter.id;
      this.reservation.centerName = this.activeCenter.name;
      this.reservation.serviceId = this.activeService.id;
      this.reservation.serviceName = this.activeService.name;
      this.formValid.emit(true);
    }
  }
  convertToDate(date: string): Date {
    return DateTime.fromISO(date).startOf('day').toJSDate();
  }
  formatTime(date: Date): string {
    return `${this.padZero(date.getHours())}:${this.padZero(date.getMinutes())}`;
  }

  padZero(number: number): string {
    return number < 10 ? '0' + number : number.toString();
  }

  cleanForm(level: number): void {
    switch (level) {
      case 1:
        this.form.patchValue({service: null, type: null, time: null, date: null});
        this.activeService = null;
        this.reservation.serviceId = null;
        this.reservation.type = null;
        this.reservation.startTime = null;
        this.reservation.endTime = null;
        break;
      case 2:
        this.form.patchValue({type: null, time: null, date: null});
        this.reservation.type = null;
        this.reservation.startTime = null;
        this.reservation.endTime = null;
        break;
      case 3:
        this.form.patchValue({time: null});
        this.reservation.startTime = null;
        this.reservation.endTime = null;
        break;
      default:
        break;
    }
  }

  generateDisabledDates(hasPeriods?: boolean): void {
    let enabledDates: Date[] = [];
    let periods: any;
    if (!hasPeriods) {
      periods = this.activeCenter.periodSchedules;
    } else {
      periods = this.activeService.periodSchedulesObject;
    }
    periods.forEach(period => {
      const startDate = this.convertToDate(period.start);
      const endDate = this.convertToDate(period.end);

      const inactiveDaysForPeriod = period.scheduleDayOfWeeks
          .filter(day => !day.isActive)
          .map(day => day.dayOfWeek);
      let currentDate = new Date(startDate);
      while (currentDate <= endDate) {
        if (!inactiveDaysForPeriod.includes(currentDate.getDay())) {
          var p = currentDate.getDay();
          enabledDates.push(new Date(currentDate));
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }
    });
    this.disabledDates = this.getAllDatesExceptEnabled(enabledDates);
  }
  getAllDatesExceptEnabled(enabledDates: Date[]): Date[] {
    let disabledDates: Date[] = [];
    const today = new Date();
    const startOfYear = new Date(today.getFullYear(), 0, 1);
    const endOfYear = new Date(today);
    if(this.activeService){
      if(this.activeService.maxPeriod){
        endOfYear.setDate(today.getDate() + this.activeService.maxPeriod );
      }else{
        endOfYear.setDate(today.getDate() + this.activeCenter.maxPeriod );
      }
    }else{
      endOfYear.setDate(today.getDate() + this.activeCenter.maxPeriod );

    }
    this.maxDate = endOfYear;
    let currentDate = new Date(startOfYear);
    while (currentDate <= endOfYear) {
      const isEnabled = enabledDates.some(enabledDate => {
        return enabledDate.toDateString() === currentDate.toDateString();
      });
      if (!isEnabled) {
        disabledDates.push(new Date(currentDate));
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return disabledDates;
  }
  groupDaysOfWeekByPeriodArray(service: ServiceDTO) {

    this.activeService.periodSchedulesObject = [];
    service.scheduleDayOfWeeks.forEach((day) => {
      let period = this.activeService.periodSchedulesObject.find(
          (p) => p.name === day.periodScheduleName
      );

      if (!period) {
        period = {
          id: day.periodScheduleId,
          name: day.periodScheduleName,
          start: day.periodScheduleStart,
          end: day.periodScheduleEnd,
          startTypeDate: DateTime.fromISO(day.periodScheduleStart).toJSDate(),
          endtypeDate: DateTime.fromISO(day.periodScheduleStart).toJSDate(),
          maxDate: null,
          minDate: null,
          scheduleDayOfWeeks: [],
          errorDateStartBigger: false,
          errorDateEndLower: false,
          errorEmptyName: false,
          errorEmptyStart: false,
          errorEmptyEnd: false
        };

        this.activeService.periodSchedulesObject.push(period);
      }

      period.scheduleDayOfWeeks.push(day);
    });

    this.activeCenter.periodSchedules.forEach((centerPeriod) => {
      const existsInService = this.activeService.periodSchedulesObject.some(
          (p) => p.id === centerPeriod.id
      );

      if (!existsInService) {
        const newPeriod = {
          id: centerPeriod.id,
          name: centerPeriod.name,
          start: centerPeriod.start,
          end: centerPeriod.end,
          startTypeDate: DateTime.fromISO(centerPeriod.start).toJSDate(),
          endtypeDate: DateTime.fromISO(centerPeriod.end).toJSDate(),
          maxDate: null,
          minDate: null,
          scheduleDayOfWeeks: centerPeriod.scheduleDayOfWeeks,
          errorDateStartBigger: false,
          errorDateEndLower: false,
          errorEmptyName: false,
          errorEmptyStart: false,
          errorEmptyEnd: false
        };

        this.activeService.periodSchedulesObject.push(newPeriod);
      }
    });
  }
}
