import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-button-loading',
  standalone: true,
  imports: [ CommonModule,
    TranslateModule ],
  templateUrl: './button-loading.component.html',
  styleUrls: ['./button-loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonLoadingComponent {

  public isLoading = false;
  public successfully = false;

  @Input()
  public pendingChanges: boolean;

  @Input()
  public actionAsync: () => Promise<any>;

  @Input()
  public text: string;

  @Input()
  public icon = "fa fa-save"

  @Input()
  public customClass = "btn-primary text-white"

  @Input()
  public disabled: boolean;

  @Input()
  public buttonType = "button";

  private finishedTimeout: any;

  constructor(translate: TranslateService){
    if (!this.text){
      this.text = translate.instant('Save');
    }
  }

  public onClick(){
    if (!this.actionAsync) return;

    this.isLoading = true;
    this.successfully = false;
    if (this.finishedTimeout) clearTimeout(this.finishedTimeout);

    this.actionAsync().then((res) => {
      if (res !== 'cancel' && res !== 'loading'){
        this.successfully = true;
        this.finishedTimeout = setTimeout(() => {
          this.successfully = false;
        }, 2000);
      }
      if (res !== 'loading'){
        this.isLoading = false;
      }
    }).catch(() => {
      this.isLoading = false;
    });
  }

 }
