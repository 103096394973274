import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SubtotalWithTaxAndFeeDTO } from 'src/app/DTO/prices/SubtotalWithTaxAndFeeDTO';
import { InscriptionsConstants } from 'src/app/pages/inscriptions/constants';

@Component({
  selector: 'app-price',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './price.component.html',
  styleUrl: './price.component.scss'
})
export class PriceComponent implements OnInit {

  @Input({ required: true }) price: SubtotalWithTaxAndFeeDTO;
  @Input() recurrence: string | null;

  @Input() priceWithoutDiscount : SubtotalWithTaxAndFeeDTO;

  public isEnrollment = false;

  public RecurrencesTypes = InscriptionsConstants.RecurrencesTypes;

  constructor(
  ) {}

  ngOnInit(): void {
    this.isEnrollment = !this.recurrence;
  }
}
