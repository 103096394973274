import {ChangeDetectorRef, Component, HostListener, OnInit} from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {CustomFieldDTO} from "../../../DTO/CustomFieldDTO";
import {DialogService, DynamicDialogConfig} from "primeng/dynamicdialog";
import {RecurrenceDTO} from "../../../DTO/RecurrenceDTO";
import {BonificationDTO} from "../../../DTO/BonificationDTO";
import {InscriptionSentDTO} from "../../../DTO/InscriptionSentDTO";
import Swal from "sweetalert2";
import {TranslateService} from "@ngx-translate/core";
import {InscriptionsDTO} from "../../../DTO/InscriptionsDTO";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { InscriptionsConstants } from '../constants';
import {CommunityService} from "../../../services/community/community.service";
import {ScreenSizeService} from "../../../services/screen-size.service";
import { firstValueFrom } from 'rxjs';
import { PaymentResult, PaymentsService } from 'src/app/services/payments/payments.service';
import { PurchaseResultDTO } from 'src/app/DTO/tickets/PurchaseResultDTO';
import { InscriptionTotalPricesDTO } from 'src/app/DTO/prices/InscriptionTotalPricesDTO';
import { UploadedFileModel } from 'src/app/components/file-uploader/file-uploader.component';

@Component({
  selector: 'app-inscription-form',
  templateUrl: './inscription-form.component.html',
  styleUrl: './inscription-form.component.scss'
})
export class InscriptionFormComponent implements OnInit{

  public isLoading = true;
  public submitLoading = false;

  public communityCode: string;

  public formGroup: FormGroup;

  public inscription: InscriptionsDTO;
  public inscriptionSent: InscriptionSentDTO;
  public customFields : CustomFieldDTO[];

  public recurrences: RecurrenceDTO[];
  public bonifications: BonificationDTO[];

  public files: any[] = [];
  public showTheDocument = false;
  public corpColor: string;
  public increment: number = 0;

  public finalPrice: number;
  public finalPriceWithDiscounts: number;
  public finalRecurrence: string;
  public isMobile: boolean;
  @HostListener('window:resize') onresize() {
    this.isMobile = this.screenSizeService.isScreenSmall();
  }
  public RecurrencesTypes = InscriptionsConstants.RecurrencesTypes;
  public prices: InscriptionTotalPricesDTO;
  public bonificationUploaded = false;
  public documentValid = true;

  constructor(
    private apiService: ApiService,
    private _formBuilder: FormBuilder,
    private dialogConfig: DynamicDialogConfig,
    private translate: TranslateService,
    private dialogService: DialogService,
    private communityService: CommunityService,
    private screenSizeService: ScreenSizeService,
    private paymentsService: PaymentsService,
    private df: ChangeDetectorRef
  ) {
  }

  async ngOnInit() {
    this.formGroup = this._formBuilder.group({});
    this.isMobile = this.screenSizeService.isScreenSmall();
    this.communityService.Get()
      .subscribe((result) => {
        this.corpColor = result.color1;
      });

    this.inscription = this.dialogConfig.data.inscription;
    this.communityCode = this.dialogConfig.data.route.snapshot.params['community'];
    this.finalRecurrence = this.inscription.defaultRecurrence;
    console.log(this.inscription);


    this.finalPrice = this.inscription.basePrice;
    this.finalPriceWithDiscounts = this.finalPrice;

    this.inscriptionSent = this.setInscriptionSent();


    if (this.inscription){
      this.apiService.getCustomFields(this.communityCode,this.inscription.id).subscribe(responseData => {
        this.customFields = responseData.filter(x=> x.publicPermissions == 'rw');


        for (const customField of this.customFields) {
          this.formGroup.addControl(customField.id.toString(), this._formBuilder.control(null));
        }
      });

      this.apiService.getBonificationsInscription(this.communityCode,this.inscription.id).subscribe(responseData => {
        this.bonifications = responseData;
      });

      this.apiService.getRecurrenceInscription(this.communityCode,this.inscription.id).subscribe(responseData => {
        let recurrences = [{
          id: null,
          type: this.inscription.defaultRecurrence,
          discount: 0
        } as RecurrenceDTO];

        this.recurrences = recurrences.concat(responseData);
      });

      await this.recalcPrice();
      this.isLoading = false;
    }
  }

  async save(){
    await this.recalcPrice();

    if (this.showTheDocument && !this.bonificationUploaded) {
      this.documentValid = false;
      Swal.fire({
        title: this.translate.instant('Oops'),
        text: this.translate.instant('INSCRIPTIONS.DocumentRequired'),
        icon: 'warning'
      })
        .then(() => {
          return
        })
    } else {
      this.documentValid = true;

      if (this.formGroup.valid) {
        this.inscriptionSent.customFields = this.customFields;
        this.inscriptionSent.inscriptionId = this.inscription.id;

        Swal.fire({
          reverseButtons: true,
          title: this.translate.instant('INSCRIPTIONS.Alert.ConfirmSendTitle'),
          html: this.translate.instant('INSCRIPTIONS.Alert.ConfirmSend', { name: this.inscription.name }),
          icon: 'warning',
          position: "top",
          showCancelButton: true,
          //confirmButtonColor: '#ef5350',
          confirmButtonText: this.translate.instant('Continue'),
          cancelButtonText: this.translate.instant('Cancel'),
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !Swal.isLoading(),
          preConfirm: (result) => {
            this.submitLoading = true;
            return firstValueFrom(this.apiService.sendInscription(this.communityCode , this.inscriptionSent))
              .then(async (sendInscriptionResult) => {
                this.processInscriptionSent(sendInscriptionResult);
              })
              .catch(error => {
                this.submitLoading = false;
                this.failedMessage();
              });
          }
        }).then((result) => {

        });
      }else{
        Swal.fire({
          position: "top" ,
          title: this.translate.instant('GENERIC.bad'),
          icon: 'warning',
          text: this.translate.instant('CheckFormFields'),
          confirmButtonText: this.translate.instant('Continue')

        });
      }
    }
  }

  saveIncrement(object: any) {

    console.log(object);

    if(object.delete){
      this.increment = this.increment - object.price;
    }else{
      this.increment = this.increment + object.price;
    }

    this.recalcPrice();
  }

  public async recalcPrice() {
    this.df.detectChanges();
    this.prices = await this.apiService.calcFinalPrice(this.communityCode, this.inscription.id, this.inscriptionSent);


    // PREU TACHAT
    this.finalPrice = this.prices.price.totalWithoutDiscount;

    let bonification = this.bonifications.find(x=> x.id == this.inscriptionSent.bonificationId);

    if (bonification) {
      this.showTheDocument = bonification.documentRequired;
    } else {
      this.showTheDocument = false;
    }

    // PREU FINAL A PAGAR PER USUARI + PREU SENSE TACHAR
    this.finalPriceWithDiscounts = this.prices.firstPayment.totalWithFee;

    let recurrence = this.recurrences.find(x => x.type == this.inscriptionSent.finalRecurrence);
    if ( recurrence){
      this.finalRecurrence = recurrence.type;
    } else {
      this.finalRecurrence = this.inscription.defaultRecurrence;
    }
  }

  public getUploadedFile(event: UploadedFileModel[]) {
    if(event.some(x => x.path)) {
      this.bonificationUploaded = true;
    }
  }

  private async processInscriptionSent(res: PurchaseResultDTO){
    try {
      let paymentRes = await this.paymentsService.processPurchaseResult(res, this.prices.firstPayment);

      this.submitLoading = false;

      if (paymentRes == PaymentResult.SUCCESS){
        this.successMessage(this.translate.instant('INSCRIPTIONS.SuccessInscription'));
        /*if (res.method){
          this.successMessage(this.translate.instant('PAYMENT.PaymentSuccess'));
        } else {
          this.successMessage(this.translate.instant('INSCRIPTIONS.SuccessInscription'));
        }*/
      } else if (paymentRes == PaymentResult.FAILED){
        this.failedMessage(this.translate.instant('PAYMENT.PaymentFailure'));
      }
    } catch (error) {
      this.failedMessage();
    }
  }

  private successMessage(title: string){
    //this.submitLoading = false;

    this.dialogService.dialogComponentRefMap.forEach(dialog => {
      dialog.destroy();
    });

    Swal.fire({
      title: title,
      position: "top",
      icon: 'success',
      html: this.inscription.confirmMessage,
      confirmButtonText: this.translate.instant('Accept')
    });
  }

  private async failedMessage(message?: string){
    //this.submitLoading = false;

    Swal.fire({
      title: this.translate.instant('Oops'),
      text: message || this.translate.instant('ERRORS.Generic'),
      icon: 'error',
      confirmButtonText: this.translate.instant('Accept'),
    });
  }

  private setInscriptionSent(): InscriptionSentDTO {
    return {
      inscriptionId: this.inscription.id,
      finalRecurrence: this.finalRecurrence,
    } as InscriptionSentDTO
  }
}
