import { APP_ID, CUSTOM_ELEMENTS_SCHEMA, Injectable, LOCALE_ID, NgModule, NgZone, TransferState } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NewsListComponent } from './pages/news/news.component';
import { NewsDetailComponent } from './pages/news/news-detail/news-detail.component';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { BannerComponent } from './components/banner/banner.component';
import { ContacteComponent } from './pages/contacte/contacte.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TimeagoIntl, TimeagoModule } from 'ngx-timeago';
import { MetadataService } from './services/metadata.service';
import 'zone.js/plugins/task-tracking';
import {EventsComponent} from "./pages/events/events.component";
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';

import {EventsDetailComponent} from "./pages/events/events-detail/events-detail.component";

import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import ca from '@angular/common/locales/ca';
import {
    NgbActiveModal,
    NgbCarousel,
    NgbPagination,
    NgbPaginationNext,
    NgbPaginationPrevious
} from '@ng-bootstrap/ng-bootstrap';
import { ShareLinkModule } from './pages/share-link/share-link.module';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { PipesModule } from './pipes/pipes.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';

import { LanguageHttpInterceptor } from './interceptors/LanguageHttpInterceptor';
import { SpinnerModule } from './components/spinner/spinner.module';
import { EventCardComponent } from './components/event-card/event-card.component';
import { SliderModule } from 'primeng/slider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';
import { ColumnLayoutComponent } from './layouts/column-layout/column-layout.component';
import { TwoColumnLayoutComponent } from './layouts/two-column-layout/two-column-layout.component';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { MapboxComponentModule } from './components/mapbox/mapbox.module';
import { PaymentModalComponent } from './pages/events/events-detail/payment-modal/payment-modal.component';
import { TicketsComponent } from './pages/events/events-detail/payment-modal/tickets/tickets.component';
import { UserDataComponent } from './pages/events/events-detail/payment-modal/user-data/user-data.component';
import { SkeletonModule } from 'primeng/skeleton';
import { InputTextModule } from 'primeng/inputtext';
import { StripePaymentComponent } from './pages/events/events-detail/payment-modal/stripe-payment/stripe-payment.component';
import { ButtonModule } from 'primeng/button';
import { translateBrowserLoaderFactory } from './languages/TranslateBrowserLoader';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { ChipsModule } from 'primeng/chips';
import { PaginatorModule } from 'primeng/paginator';
import { CarouselModule } from 'primeng/carousel';
import 'add-to-calendar-button';
import { TicketQrComponent } from './pages/events/events-detail/payment-modal/tickets/ticket-qr/ticket-qr.component';
import { QRCodeModule } from 'angularx-qrcode';
import {CustomFieldFormComponent} from "./components/custom-field-input/custom-field-input.component";
import {InputTextareaModule} from "primeng/inputtextarea";
import {InputSwitchModule} from "primeng/inputswitch";
import {InscriptionsDetailComponent} from "./pages/inscriptions/inscriptions-detail/inscriptions-detail.component";
import {InscriptionsComponent} from "./pages/inscriptions/inscriptions.component";
import {InscriptionCardComponent} from "./components/inscription-card/inscription-card.component";
import {InscriptionFormComponent} from "./pages/inscriptions/inscription-form/inscription-form.component";
import {CustomFieldDisplayComponent} from "./components/custom-field-display/custom-field-display.component";
import {FileUploaderComponentModule} from "./components/file-uploader/file-uploader.module";
import { TooltipModule } from 'primeng/tooltip';
import { RelativeTimePipe } from './pipes/RelativeTimePipe/relative-time.pipe';
import { RelativeTimeClock } from './pipes/RelativeTimePipe/relative-time.clock';
import {PAMComponent} from "./pages/pam/pam.component";
import {PamActionComponent} from "./pages/pam/pam-action/pam-action.component";
import {ProgressBarModule} from "primeng/progressbar";
import {PamLayoutComponent} from "./layouts/pam-layout/pam-layout.component";
import {BannerPamComponent} from "./components/banner-pam/banner-pam.component";
import {AccordionModule} from "primeng/accordion";
import {TableModule} from "primeng/table";
import {StatusColorComponent} from "./components/status-color/status-color.component";
import {LocationSelectorComponentModule} from "./components/location-selector/location-selector.module";
import {ConvertSvgComponent} from "./components/convert-svg/convert-svg.component";
import {PamAreaDetailComponent} from "./pages/pam/pam-area-detail/pam-area-detail.component";
import {PamTypeDetailComponent} from "./pages/pam/pam-type-detail/pam-type-detail.component";
import {PamOdsDetailComponent} from "./pages/pam/pam-ods-detail/pam-ods-detail.component";
import { PriceComponent } from "./components/price/price.component";
import {CheckboxModule} from "primeng/checkbox";
import {TimelineModule} from "primeng/timeline";
import {
    ReservationStep1Component
} from "./pages/prior-appointment/new-reservation/reservation-step1/reservation-step1.component";
import {
    FinishReservationComponent
} from "./pages/prior-appointment/new-reservation/finish-reservation/finish-reservation.component";
import {
    ReservationStep2Component
} from "./pages/prior-appointment/new-reservation/reservation-step2/reservation-step2.component";
import {PriorAppointmentComponent} from "./pages/prior-appointment/prior-appointment.component";
import {ReservationDetailComponent} from "./pages/prior-appointment/reservation-detail/reservation-detail.component";
import {NewReservationComponent} from "./pages/prior-appointment/new-reservation/new-reservation.component";
import {RadioButtonModule} from "primeng/radiobutton";
import {SelectButtonModule} from "primeng/selectbutton";
import {ButtonLoadingComponent} from "./components/button-loading/button-loading.component";

registerLocaleData(es);
registerLocaleData(ca);

@Injectable()
export class MyIntl extends TimeagoIntl {
  // do extra stuff here...
}

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    NewsListComponent,
    NewsDetailComponent,
    EventsDetailComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    BannerComponent,
    ContacteComponent,
    EventsComponent,
    EmptyStateComponent,
    TicketQrComponent,
    EventCardComponent,
    InscriptionCardComponent,
    ColumnLayoutComponent,
    TwoColumnLayoutComponent,
    PamLayoutComponent,
    TicketsComponent,
    PaymentModalComponent,
    UserDataComponent,
    StripePaymentComponent, ColumnLayoutComponent,
    TwoColumnLayoutComponent,
    InscriptionsDetailComponent,
    InscriptionsComponent,
    InscriptionFormComponent,
    CustomFieldDisplayComponent,
    PAMComponent,
    PamActionComponent,
  PamAreaDetailComponent,
      PamTypeDetailComponent,
      PamOdsDetailComponent,
      PriorAppointmentComponent,
      ReservationDetailComponent,
      NewReservationComponent,
      FinishReservationComponent,
      ReservationStep2Component,
      ReservationStep1Component
  ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        FontAwesomeModule,
        NgxSkeletonLoaderModule,
        TimeagoModule.forRoot({
            intl: {provide: TimeagoIntl, useClass: MyIntl},
            //formatter: { provide: TimeagoFormatter, useClass: TimeagoCustomFormatter },
        }),
        NgbPagination,
        NgbPaginationNext,
        NgbPaginationPrevious,
        ShareLinkModule,
        PipesModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateBrowserLoaderFactory,
                deps: [HttpClient, TransferState]
            }
        }),
        SpinnerModule,
        CarouselModule,
        SliderModule,
        FormsModule,
        InputNumberModule,
        SliderModule,
        FormsModule,
        InputNumberModule,
        DynamicDialogModule,
        MapboxComponentModule,
        NgbCarousel,
        ReactiveFormsModule,
        SkeletonModule,
        InputTextModule,
        ButtonModule,
        DropdownModule,
        OverlayPanelModule,
        CalendarModule,
        MultiSelectModule,
        ChipsModule,
        OverlayPanelModule,
        PaginatorModule,
        QRCodeModule,
        InputTextareaModule,
        InputSwitchModule,
        CustomFieldFormComponent,
        FileUploaderComponentModule,
        TooltipModule,
        RelativeTimePipe,
        ProgressBarModule,
        BannerPamComponent,
        AccordionModule,
        TableModule,
        StatusColorComponent,
        LocationSelectorComponentModule,
        ConvertSvgComponent,
        PriceComponent,
        CheckboxModule,
        TimelineModule,
        RadioButtonModule,
        SelectButtonModule,
        ButtonLoadingComponent,

    ],
  providers: [
    provideHttpClient(withFetch()),
    TimeagoIntl,
    MetadataService,
    {provide: LOCALE_ID, useValue: 'es-ES'},
    {provide: APP_ID, useValue: 'serverApp'},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageHttpInterceptor,
      multi: true,
      deps: [TranslateService]
    },
    DialogService,
    RelativeTimeClock,
      NgbActiveModal
  ],
  bootstrap: [AppComponent],
  exports: [
    CustomFieldFormComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor(ngZone: NgZone) {
    /**
     * CONFIGURE how long to wait (in seconds)
     * before the pending tasks are dumped to the console.
     */
    /*
    const WAIT_SECONDS = 5;

    console.log(
      `⏳ ... Wait ${WAIT_SECONDS} seconds to dump pending tasks ... ⏳`
    );

    // Run the debugging `setTimeout` code outside of
    // the Angular Zone, so it's not considered as
    // yet another pending Zone Task:
    ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        // Access the NgZone's internals - TaskTrackingZone:
        const TaskTrackingZone = (ngZone as any)._inner
          ._parent._properties.TaskTrackingZone;

        // Print to the console all pending tasks
        // (micro tasks, macro tasks and event listeners):
        console.debug('👀 Pending tasks in NgZone: 👀');
        console.debug({
          microTasks: TaskTrackingZone.getTasksFor('microTask'),
          macroTasks: TaskTrackingZone.getTasksFor('macroTask'),
          eventTasks: TaskTrackingZone.getTasksFor('eventTask'),
        });

        // Advice how to find the origin of Zone tasks:
        console.debug(
          `👀 For every pending Zone Task listed above investigate the stacktrace in the property 'creationLocation' 👆`
        );
      }, 1000 * WAIT_SECONDS);
    });
    */
  }
 }
