import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ReservationDTO} from "../../../../DTO/PriorAppointment/ReservationDTO";
import {CommunityDTO} from "../../../../DTO/CommunityDTO";
import {ThemeService} from "../../../../services/theme/theme.service";
import jspdf from 'jspdf';
import {TranslateService} from "@ngx-translate/core";
import Swal from "sweetalert2";
import {ApiService} from "../../../../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-finish-reservation',
  templateUrl: './finish-reservation.component.html',
  styleUrl: './finish-reservation.component.scss'
})
export class FinishReservationComponent implements OnInit{
  @Input() nextAttempt = new EventEmitter();
  @Input() itsUpdating:boolean = false;
  @Input() public reservation: ReservationDTO;
  @Input() public community: CommunityDTO;
  @ViewChild('content_pdf') content: ElementRef;
  communityName: string;


  constructor(public _themeService: ThemeService,
              public _translate: TranslateService,
              private apiService: ApiService,
              private router: Router,
              private actRoute: ActivatedRoute) {

  }
  ngOnInit(): void {
    this.communityName = this.actRoute.snapshot.params['community'];
  }
  _export() {
    return async () => {
      await this.exportPDF();
    }
  }
  private async exportPDF() {

    let doc = new jspdf({
      orientation: 'p',
      unit: 'pt',
      format: 'a4',
      putOnlyUsedFonts:true,
      precision: 2
    });
    var d = this.reservation.name;
    await doc.html(this.content.nativeElement.innerHTML, {
      autoPaging: "text",
      callback: function (doc) {
        doc.save('Reserva - '+ d +'.pdf');
      },
      width: 500,
      margin: 40,
      windowWidth: 500,
    } );

  }

  public deleteReservation() {
    Swal.fire({
      reverseButtons: true,
      title: this._translate.instant('PRIORAPPOINTMENT.deleteConfirmationTitle'),
      text: this._translate.instant('PRIORAPPOINTMENT.deleteConfirmationText'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#2f4050',
      confirmButtonText: this._translate.instant('PRIORAPPOINTMENT.confirmButtonText'),
      cancelButtonText: this._translate.instant('PRIORAPPOINTMENT.cancelButtonText'),
      preConfirm: (result) => {
        Swal.fire({
          title: this._translate.instant('PRIORAPPOINTMENT.deletingReservationTitle'),
          icon: 'info',
          showCancelButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
            this.apiService.deleteReservation(this.community.code, this.reservation).toPromise()
                .then((res) => {
                  Swal.fire({
                    title: this._translate.instant('PRIORAPPOINTMENT.genericSuccess'),
                    icon: 'success',
                    text: this._translate.instant('PRIORAPPOINTMENT.reservationDeleted'),
                    confirmButtonText: this._translate.instant('PRIORAPPOINTMENT.genericAccept')
                  });
                  this.router.navigate([this.communityName + '/prior-appointment/']);
                })
                .catch(error => {
                  Swal.fire({
                    title: this._translate.instant('PRIORAPPOINTMENT.genericError'),
                    icon: 'error',
                    html: this._translate.instant('PRIORAPPOINTMENT.genericError'),
                    confirmButtonText: this._translate.instant('PRIORAPPOINTMENT.genericAccept')
                  });
                });
          }
        }).then((result) => {
        });
      }
    });
  }


}
