import {Component, OnInit} from '@angular/core';
import {ActionDTO} from "../../../DTO/PAM/ActionDTO";
import {ApiService} from "../../../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {CommunityService} from "../../../services/community/community.service";
import {ThemeService} from "../../../services/theme/theme.service";
import {CommunityDTO} from "../../../DTO/CommunityDTO";
import {AxisDTO} from "../../../DTO/PAM/AxisDTO";
import {MetadataService} from "../../../services/metadata.service";
import {Functions} from "../../../helpers/Functions";
import {LocationStrategy} from "@angular/common";

@Component({
  selector: 'app-pam-action',
  templateUrl: './pam-action.component.html',
  styleUrl: './pam-action.component.scss'
})
export class PamActionComponent implements OnInit{
  public loading: boolean;
  public id: number;
  public actionDTO: ActionDTO;
  public axisDTO: AxisDTO;
  public state: string = '';
  communityName = "";
  community: CommunityDTO;
  constructor(private apiService: ApiService,
              private actRoute: ActivatedRoute,
              private location: LocationStrategy,
              private translate: TranslateService,
              private communityService: CommunityService,
              private themeService: ThemeService,
              private metadataService: MetadataService,
              private router: Router) {
  }
  ngOnInit(): void {
    const state: any = this.location.getState();
    this.state = state?.url
    this.loading = true;
    this.id = Number(this.actRoute.snapshot.paramMap.get('id'));
    this.communityName = this.actRoute.snapshot.params['community'];
    this.communityService.Get().subscribe(res=>{
      this.community = res;
      if (this.community ) {
        this.metadataService.updateMetadata({
          title: Functions.decode( this.community.name) + " - " + this.translate.instant('PAM.titleTab'),
        });
      }
    });

    this.apiService.getActionDetail(this.id , this.communityName).subscribe((res: ActionDTO) => {
      this.actionDTO = res;
      this.actionDTO.coords = this.actionDTO.actuations
          .filter(a => a.lat !== undefined && a.lng !== undefined)
          .map(a => ({ lat: a.lat, lng: a.lng }));
          this.apiService.getAxisDetail(this.actionDTO.axisId , this.communityName).subscribe((res2: AxisDTO) => {
            this.axisDTO = res2;
            this.loading = false;
          });
      });

  }
  getColorWithOpacity(color: string, opacity: number): string {
    if (color.startsWith('#')) {
      const r = parseInt(color.slice(1, 3), 16);
      const g = parseInt(color.slice(3, 5), 16);
      const b = parseInt(color.slice(5, 7), 16);
      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    }
    if (color.startsWith('rgb')) {
      return color.replace('rgb', 'rgba').replace(')', `, ${opacity})`);
    }
    return color;
  }
  goBack(): void {
    if(this.state){
      this.router.navigate([this.communityName+ '/PAM/'+ this.state]);
    }else{
      this.router.navigate([this.communityName+ '/PAM']);
    }

  }
  routeNew(slug:string){
    this.router.navigate([this.communityName+'/news/' + slug ]);
  }

}
