import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LatLng, LatLngBounds } from 'leaflet';
import { environment } from 'src/environments/environment';
import { GeoCodeResponseDTO } from '../DTO/mapbox/GeocodeResponseDTO';



@Injectable({
  providedIn: 'root'
})
export class MapboxService {

  constructor(
    private  http: HttpClient,
  ) { }


  Geocode(search_text: string, bbox: LatLngBounds) {
    const bboxStr = (bbox) ? `&bbox=${bbox.toBBoxString()}` : '';    
    return new Promise<GeoCodeResponseDTO>((resolve, reject) => {      
      let url: string =`https://api.mapbox.com/geocoding/v5/mapbox.places/${search_text}.json?`;
      url += (bbox != null ? `types=address,poi${bboxStr}` : `proximity=ip`) + `&access_token=${environment.MAPBOX_TOKEN}`;      
      this.http.get(url)
        .subscribe((data: any) => {
          console.log(['MAPBOX RESPONSE', data]);
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  reverseGeocode(c: LatLng) {
    return new Promise<any>((resolve, reject) => {
      this.http.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${c.lng},${c.lat}.json?types=address,poi&access_token=${environment.MAPBOX_TOKEN}`)
        .subscribe((data: any) => {
          console.log(['MAPBOX RESPONSE', data]);
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  reverseGeocode_GetStreetAndNumber(c: LatLng) {
    return new Promise<any>((resolve) => {
      this.reverseGeocode(c).then((data: any) => {        
          const address = data.features[0].address;
          if (address && typeof address !== 'undefined' && address !== 'undefined') {            
            resolve(data.features[0].text + ' ' + address);
          } else {            
            resolve(data.features[0].text);
          }
        });
    });
  }
}
