export class UserDTO {
    id: number;
    username: string;
    name: string;
    surname: string;
    mail: string;
    dni: string;
    phone: string;
    image: string;
    acceptanceCommercial: Date;
}
