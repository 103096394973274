import {Component, OnInit} from '@angular/core';
import {CommunityService} from "../../services/community/community.service";
import {CommunityDTO} from "../../DTO/CommunityDTO";
import {MandateDTO} from "../../DTO/PAM/MandateDTO";
import {ApiService} from "../../services/api.service";
import {CommonModule} from "@angular/common";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {SkeletonModule} from "primeng/skeleton";
import {PipesModule} from "../../pipes/pipes.module";

@Component({
  selector: 'app-banner-pam',
  standalone: true,
  templateUrl: './banner-pam.component.html',
  imports: [
    CommonModule,
    PipesModule,
    SkeletonModule,
    TranslateModule
  ],
  styleUrl: './banner-pam.component.scss'
})
export class BannerPamComponent implements OnInit{
  loading: boolean;
  community: CommunityDTO;
  selectedMandate: MandateDTO;
  constructor(private communityService: CommunityService,
              private apiService: ApiService,
              private translate: TranslateService) {
  }
  ngOnInit(): void {
    this.loading = true;
    this.communityService.Get().subscribe(res=>{
      this.community = res;
      this.apiService.getMandates(this.community.code).subscribe((res: MandateDTO[]) => {
        if (res.length) {
          this.selectedMandate = res
              .filter(x => x.end && x.active)
              .reduce((ultimo, actual) => {
                return new Date(actual.end) > new Date(ultimo.end) ? actual : ultimo;
              });
          this.selectedMandate.nameSelector = new Date(this.selectedMandate.start).getFullYear() + '-' + new Date(this.selectedMandate.end).getFullYear();
      }
        this.loading = false;
    })
  });
  }
  convertHexToRgba(hex: string, alpha: number): string {
    hex = hex.replace('#', '');

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

}
