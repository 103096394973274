import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject, forkJoin, from } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { API_URL, environment } from 'src/environments/environment';
import { ApiService } from '../api.service';
import { ResettableSubject } from '../../helpers/ResettableSubject';
import { CommunityDTO } from 'src/app/DTO/CommunityDTO';
import { CommunityModuleDTO } from 'src/app/DTO/CommunityModuleDTO';
import { CommunityType } from 'src/app/DTO/ModalData';

export class ParamKeys {
  public static readonly LANGUAGES = 'LANGUAGES';
  public static readonly APP_LOGIN_REQUIRED = 'APP.LOGIN_REQUIRED';
  public static readonly APP_DISABLE_REGISTER = 'APP.DISABLE_REGISTER';
}

@Injectable({
  providedIn: 'root'
})
export class CommunityService {

  public defaultImg: string;

  private _community: BehaviorSubject<CommunityDTO> = new BehaviorSubject(null);
  private _filteredModules = new BehaviorSubject<CommunityModuleDTO[]>(null);

  menuTabs: any[];

  private parameterName = "SHOW_IN_PORTAL";

  private modulesKeysInMenu: {} = {
    'NEWS': 'news',
    'CALENDAR': 'events',
    'INCIDENTS': 'incidents',
    'INSCRIPTIONS': 'inscriptions',
    'PAM': 'PAM',
    'PRIORAPPOINTMENT': 'prior-appointment'
  };

  constructor(
    private api: ApiService) { }

  public Get(): Observable<CommunityDTO> {
    return this._community.asObservable();
  }

  public async Set(c: any) {
    //console.log("CommunityService set", c);
    this.defaultImg = c.parameters.find(x => x.key == 'PLACEHOLDER_IMAGE')?.value;
    //console.log('defaultImg', this.defaultImg);
    this._community.next(c);

    this.refreshModulesList();
    // this.setMenuTabs();
  }

  public GetParameters(): Observable<{}> {
    return this.Get().pipe(
      map((c) => {
        if (!c || !c.parameters) {
          return {};
        }

        //Map an array of objects to a dictionary
        let parameters = Object.assign({}, ...c.parameters.map((x: any) => ({ [x.key]: x.value })));
        return parameters;
      })
    );
  }

  public GetCommunityType(): Observable<CommunityType> {
    return this.Get().pipe(map(x => x?.communityType));
  }

  public GetModules(): Observable<CommunityModuleDTO[]> {
    let obs = this._filteredModules;

    return obs.asObservable().pipe(
      map(x => x?.filter(x => this.modulesKeysInMenu[x.moduleTypeKey]))
    );
  }

  public GetModulesDict(): Observable<{}> {
    return this.GetModules()
      .pipe(
        map(x => {
          if (x) {
            let dict = {};
            x.forEach(el => {
              dict[el.moduleTypeKey] = el;
            });
            return dict
          }
        })
      );
  }

  public RefreshAndGetModules(): Observable<CommunityModuleDTO[]> {
    this.refreshModulesList();

    return this.GetModules();
  }

  public async MakeShareLink(path: string): Promise<string> {
    const c = await this.Get().pipe(first()).toPromise()
    const shareBaseLink = await this.GetShareBaseLink();

    return shareBaseLink + "/" + c.code + path;
  }

  private _GetModules(): Observable<CommunityModuleDTO[]> {
    const communityId = this._community.value.id;
    return this.api.GetModules(communityId);
  }

  private refreshModulesList() {
    let subj = this._filteredModules;


    let modules = this._GetModules();
    modules.subscribe((res) => {
      subj.next(res);
    });
  }

  public setMenuTabs(): Promise<any[]> {
    return new Promise<any> (async (resolve, reject) => {
      try {
        this.GetModules()
          .subscribe(modules => {
            this.menuTabs = [];

            if (modules) {
              modules.forEach(module => {

                const showInMenu = module.parameters.find(x => {
                  const key: string = x.key;
                  return key.includes(this.parameterName) && x.value === 'True';
                })
                if (showInMenu) {
                  this.menuTabs.push({ name: module.name, key: module.moduleTypeKey, route: this.modulesKeysInMenu[module.moduleTypeKey] })
                }
              });

              const customSort = (a, b) => {
                const indexA = Object.keys(this.modulesKeysInMenu).indexOf(a.key);
                const indexB = Object.keys(this.modulesKeysInMenu).indexOf(b.key);

                return indexA - indexB;
              }

              this.menuTabs = this.menuTabs.sort(customSort);
              resolve(this.menuTabs)
            }
          })
      }
      catch (err) {
        reject(err)
      }
    })
  }


  private async GetShareBaseLink(): Promise<string> {
    let type = await this.GetCommunityType().pipe(first()).toPromise();

    if (type == CommunityType.Town) {
      if (environment.production) {
        return 'https://link.wetown.app'
      } else {
        return `https://link.${environment.tag}.wetown.app`;
      }
    } else {
      if (environment.production) {
        return 'https://link.wecoo.app'
      } else {
        return `https://link.${environment.tag}.wecoo.app`;
      }
    }
  }

}
