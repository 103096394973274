import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationSelectorComponent } from './location-selector.component';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    LocationSelectorComponent,
  ],
  imports: [
    CommonModule,    
    FormsModule,
    AutoCompleteModule,
    TranslateModule
],
  exports: [
    LocationSelectorComponent
  ]
})
export class LocationSelectorComponentModule {}