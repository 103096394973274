import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild, type OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { API_URL } from 'src/environments/environment';
import { CommunityService } from 'src/app/services/community/community.service';
import { first } from 'rxjs/operators';
import { SafePipe } from 'src/app/pipes/safe';
import { LanguageService } from '../../language.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PaymentResult } from '../payments.service';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { PaymentStatus, PurchaseResultDTO } from 'src/app/DTO/tickets/PurchaseResultDTO';

@Component({
    selector: 'app-redsys-modal',
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        SafePipe
    ],
    templateUrl: './modal-redsys.component.html',
    styleUrls: ['./modal-redsys.component.scss'],
    //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RedsysModalComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('iframeElement', { static: false }) iframeElement: ElementRef;
    
    public isLoading = true;
    public iframeUrl = null;

    private purchaseUuid: string;
    private orderId: string;

    private connection: HubConnection;
    
    constructor(
        private translate: TranslateService,
        private languageService: LanguageService,
        private communityService: CommunityService,
        private config: DynamicDialogConfig,
        private dialogRef: DynamicDialogRef
    ) {}

    async ngOnInit(): Promise<void> {
        this.purchaseUuid = this.config.data.purchaseUuid;
        this.orderId = this.config.data.orderId;

        this.connection = new HubConnectionBuilder()
        .withUrl(API_URL + '/hubs/payments', {
          withCredentials: false
        })
        .build();
        
        // On payment result
        this.connection.on("payment-result", (res: PurchaseResultDTO ) => {
        if (res.status == PaymentStatus.PAID){
            this.dialogRef.close(PaymentResult.SUCCESS);
            //this.reload();
            //this.purchaseSuccessMessage();
        } else if (res.status == PaymentStatus.ERROR){
            this.dialogRef.close(PaymentResult.FAILED);
        } else {
          console.warn("payment-result: " + res.status);
        }
      });
    }

    async ngAfterViewInit(): Promise<void> {
        var c = await this.communityService.Get().pipe(first()).toPromise();
        this.iframeUrl = `${API_URL}/v2/Communities/${c.code}/Payments/Redsys/redirection?uuid=${this.purchaseUuid}&orderId=${this.orderId}`;
        this.isLoading = false;
        
        setTimeout(() => {
            this.iframeElement.nativeElement.addEventListener('load', () => {
              try {
                const location = this.iframeElement.nativeElement.contentWindow.location;
                //console.log('iframe location:', location);
                //console.log('iframe href:', location.href);
                if (location.hostname == 'localhost'){
                    //this.modalCtrl.dismiss(null, 'redsys-return');
                    this.dialogRef.close(PaymentResult.CANCEL);
                }
              } catch (error) {
                // Forbidden to get iFrame URL from redsys (cross-domain security)
              }
            });
        }, 0);

         // Connection stat
        this.connection.start()
        .then(_ => {
            console.log('Connection Started');
            this.connection.invoke('WaitForPayment', this.purchaseUuid);
        }).catch(error => {
            console.error(error);
        });
    }

    ngOnDestroy(){
        this.connection.stop();
    }

    public cancel() {
        this.dialogRef.close(PaymentResult.CANCEL);
    }

}
