import {Component, OnInit} from '@angular/core';
import {MandateDTO} from "../../DTO/PAM/MandateDTO";
import {ApiService} from "../../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {CommunityService} from "../../services/community/community.service";
import {ThemeService} from "../../services/theme/theme.service";
import {PortalDataDTO} from "../../DTO/PAM/PortalDataDTO";
import {AxisDTO} from "../../DTO/PAM/AxisDTO";
import {CommunityDTO} from "../../DTO/CommunityDTO";
import {AreaDTO} from "../../DTO/PAM/AreaDTO";
import {ODSDTO} from "../../DTO/PAM/ODSDTO";
import {TypeDTO} from "../../DTO/PAM/typeDTO";
import {Functions} from "../../helpers/Functions";
import {MetadataService} from "../../services/metadata.service";
@Component({
  selector: 'app-pam',
  templateUrl: './pam.component.html',
  styleUrl: './pam.component.scss',
})
export class PAMComponent implements OnInit{
  public loading: boolean;
  public mandates: MandateDTO[] = [];
  public axes: AxisDTO[] = [];
  public areas: AreaDTO[] = [];
  public tipus: TypeDTO[] = [];
  public ods: ODSDTO[] = [];
  public selectedMandate: MandateDTO;
  public portalData: PortalDataDTO;
  public selectedFilter: any ;
  public filters: any [];
  activeIndex: number;
  responsiveOptions: any[] | undefined;
  boxes: any[] | undefined;
  activeIndexActions: number;
  fillHeight: string = '0%';
  communityName = "";
  community: CommunityDTO;
  constructor(private apiService: ApiService,
              private actRoute: ActivatedRoute,
              private translate: TranslateService,
              private communityService: CommunityService,
              private themeService: ThemeService,
              private router: Router,
              private metadataService: MetadataService) {
  }
  ngOnInit(): void {

    this.reload();
    this.actRoute.fragment.subscribe(fragment => {
     console.log(fragment);
     if(fragment){
       switch (fragment) {
         case "area":
           this.selectedFilter = {id: 1 , name: this.translate.instant('PAM.areas').toUpperCase()};
           break;
         case "ods":
           this.selectedFilter = {id: 3 , name: 'ODS'};
           break;
         case "types":
           this.selectedFilter = {id: 2 , name: this.translate.instant('PAM.type').toUpperCase()};
           break;
         default:
           break;
       }
     }
    });
  }
  public reload() {
    this.loading = true;
    this.communityName = this.actRoute.snapshot.params['community'];
    this.communityService.Get().subscribe(res=>{
      this.community = res;
      if (this.community ) {
        this.metadataService.updateMetadata({
          title: Functions.decode( this.community.name) + " - " + this.translate.instant('PAM.titleTab'),
        });
      }
    });
    this.apiService.getMandates(this.communityName).subscribe((res: MandateDTO[]) => {
      this.mandates = res;


      this.mandates.map(x => {
        x.nameSelector = 'PAM ' + new Date(x.start).getFullYear() + '-' + new Date(x.end).getFullYear();
      });
      if (res.length) {
        this.selectedMandate = res
            .filter(x => x.end && x.active)
            .reduce((ultimo, actual) => {
              return new Date(actual.end) > new Date(ultimo.end) ? actual : ultimo;
            });
        this.selectedMandate.axes = this.selectedMandate.axes.filter(x=>!x.isDeleted && x.isActive);
        this.apiService.getDataPortal(this.communityName , this.selectedMandate.id).subscribe((portalData: PortalDataDTO) => {
         this.portalData = portalData;
            this.boxes = [{
              text: this.translate.instant('PAM.boxProgres'),
              value:this.portalData.percent,
              progressBar: true
            },
            {
              text: this.translate.instant('PAM.boxAxes'),
              value:this.portalData.nAxes,
              progressBar: false
            },
            {
              text: this.translate.instant('PAM.boxActions'),
              value:this.portalData.nActions,
              progressBar: false
            },
            {
              text: this.translate.instant('PAM.boxActuations'),
              value:this.portalData.nActuactions,
              progressBar: false
            }]
        });
        this.apiService.getAxis(this.communityName , this.selectedMandate.id).subscribe((axes: AxisDTO[]) => {
          this.axes = axes;
        });
      }
      this.loading = false;
    });
    this.filters = [
      {id: 0 , name: this.translate.instant('PAM.boxAxes')},
      {id: 1 , name: this.translate.instant('PAM.areas').toUpperCase()},
      {id: 2 , name: this.translate.instant('PAM.type').toUpperCase()},
      {id: 3 , name: 'ODS'}]
    this.selectedFilter =  {id: 0 , name: this.translate.instant('PAM.boxAxes')};
    this.apiService.getAreas(this.communityName).subscribe((res: AreaDTO[]) => {
      this.areas = res;
    });
    this.apiService.getODS(this.communityName).subscribe((res: ODSDTO[]) => {
      this.ods = res;
    });
    this.apiService.getTypes(this.communityName).subscribe((res: TypeDTO[]) => {
      this.tipus = res;
    });
  }
  activeIndexChange(index: any) {
    this.activeIndex = this.activeIndex === index ? -1 : index;
  }

  activeIndexChangeActions(index: any) {
    this.activeIndexActions = this.activeIndexActions === index ? -1 : index;
  }
  goDetail(id: number , event: any ){
    event.preventDefault();
    event.stopPropagation();
    this.router.navigate([this.communityName+'/PAM/action/' + id]);
  }

  getColorWithOpacity(color: string, opacity: number): string {
    if (color.startsWith('#')) {
      const r = parseInt(color.slice(1, 3), 16);
      const g = parseInt(color.slice(3, 5), 16);
      const b = parseInt(color.slice(5, 7), 16);
      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    }
    if (color.startsWith('rgb')) {
      return color.replace('rgb', 'rgba').replace(')', `, ${opacity})`);
    }
    return color;
  }
  getWaveTransform(progress: number): string {
    return `translateY(${100 - progress}%)`;
  }
  linkTodetail(path: string, id: number){
    this.router.navigate([this.communityName+'/PAM/' +path+'/' + id]);
  }
}
