import { TranslateService } from '@ngx-translate/core';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/ca';
import { TimeagoIntl } from 'ngx-timeago';
import { strings as TimeagoEs } from 'ngx-timeago/language-strings/es';
import { strings as TimeagoCa } from 'ngx-timeago/language-strings/ca';
import { strings as TimeagoEn } from 'ngx-timeago/language-strings/en';
import { CommunityService, ParamKeys } from './community/community.service';
import { Settings } from 'luxon';
import { BehaviorSubject, combineLatest, firstValueFrom } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { PrimeNGConfig } from 'primeng/api';
const LNG_KEY = 'LANGUAGE';

const ALL_LANGUAGES: { key: string, name: string }[] = [
  {
    key: 'ca',
    name: 'Català'
  },
  {
    key: 'es',
    name: 'Español'
  },
  {
    key: 'en',
    name: 'English'
  }
];

export const DEFAULT_LANGUAGE = 'es';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private availableLanguages = new BehaviorSubject<{ key: string, name: string }[]>(ALL_LANGUAGES);
  private currentLang = new BehaviorSubject<string>(this.translate.currentLang);
  private isBrowser: boolean;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private timeAgoIntl: TimeagoIntl,
    //private auth: AuthService,
    private communityService: CommunityService,
    //private userService: UsersService,
    private activeRoute: ActivatedRoute,
    private primengConfig: PrimeNGConfig,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  public init() {
    const browserLang = this.getBrowserLang();
    this.translate.setDefaultLang(browserLang ?? DEFAULT_LANGUAGE);

    combineLatest([this.activeRoute.queryParamMap, this.communityService.GetParameters()])
    .subscribe(([queryParams, parameters]) => {
      if (parameters[ParamKeys.LANGUAGES]){
        let paramLangs = (parameters[ParamKeys.LANGUAGES] as string).split(',');

        let langs = ALL_LANGUAGES.filter(x => paramLangs.includes(x.key));
        langs.sort((a, b) =>
          paramLangs.indexOf(a.key) - paramLangs.indexOf(b.key)
        );
        this.availableLanguages.next(langs);

        this.translate.setDefaultLang(this.availableLanguages.value[0].key);
        this.checkLocalStorage(this.availableLanguages.value[0].key, queryParams.get('lang'));
      } else {
        this.availableLanguages.next(ALL_LANGUAGES);
        this.checkLocalStorage(browserLang ?? DEFAULT_LANGUAGE, queryParams.get('lang'));
      }
    });

    this.translate.onLangChange.subscribe((res) => {
      this.currentLang.next(res.lang);
    })
  }

  public async setLanguage(lng: string, persist = false) {

    if (lng == this.translate.currentLang) return;

    if (!this.availableLanguages.value.some(x => x.key == lng)){
      lng = this.availableLanguages.value[0].key;
    }

    await firstValueFrom(this.translate.use(lng));

    //Moment
    moment.locale(lng);
    // Luxon
    Settings.defaultLocale = lng;
    //TimeAgo
    this.configureTimeAgo(lng);

    // PrimeNG
    this.configurePrimeNg(lng);

    if (persist){
      //save localStorage
      localStorage.setItem(LNG_KEY, lng);
    }

  }

  public getAvailableLanguages(){
    return this.availableLanguages.asObservable();
  }

  public getCurrentLang(){
    return this.currentLang.asObservable();
  }

  private getBrowserLang(){
    const browserLang = this.translate.getBrowserLang();
    if (ALL_LANGUAGES.map(x => x.key).includes(browserLang)){
      return browserLang;
    }
    return null;
  }

  private configureTimeAgo(lng: string){
    switch (lng) {
      case 'ca':
        this.timeAgoIntl.strings = TimeagoCa;
        this.timeAgoIntl.strings.hours = '%d hores';
        this.timeAgoIntl.strings.month = '1 mes';
        this.timeAgoIntl.strings.year = '1 any';
        this.timeAgoIntl.strings.prefixFromNow = 'd\'aquí';
        break;
      case 'es':
        this.timeAgoIntl.strings = TimeagoEs;
        break;
      case 'en':
        this.timeAgoIntl.strings = TimeagoEn;
        break;
    }

    this.timeAgoIntl.changes.next();
  }

  private configurePrimeNg(lng: string){
    this.http.get(`/assets/primelocale/${lng}.json`).subscribe((res: any) => {
      switch (lng) {
        case 'es':
          this.primengConfig.setTranslation(res.es);
          break;
        case 'ca':
          this.primengConfig.setTranslation(res.ca);
          break;
        case 'en':
          this.primengConfig.setTranslation(res.en);
          break;
      }
    });
  }


  private async checkLocalStorage(fallback: string, queryLang: string = null){
    if (queryLang){
      this.setLanguage(queryLang);
      return;
    }

    if (!this.isBrowser) return;

    const storageLang = localStorage.getItem(LNG_KEY);
    if (storageLang) {
      this.setLanguage(storageLang);
    }else{
      this.setLanguage(fallback);
    }
  }
}
