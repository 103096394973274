import { Pipe, PipeTransform } from '@angular/core';
import { BUCKET_NAME, environment } from 'src/environments/environment';

@Pipe({
  name: 'imageUrl'
})
export class ImageUrlPipe implements PipeTransform {

  transform(value: unknown, isFile: boolean = false): string {
    if(!value){
      return '';
    }

    if(!isFile) {
      return "https://storage.googleapis.com/" + BUCKET_NAME + "/imgs" + value;
    } else {
      return `${environment.storageURL}${value}`
    }
  }

}
