import { LatLng, LatLngBounds, LatLngLiteral, Polygon } from "leaflet";

export class CityPolygonModel {

    private geojson: GeoJSON.Polygon | GeoJSON.MultiPolygon;
    private coordinates: number[][];
    private boundingbox: LatLngBounds;
    public center: LatLng;

    constructor(
        private L: any,
        geojson: GeoJSON.GeometryObject,
    ){

        if(geojson.type == "Polygon"){
            this.coordinates = geojson.coordinates[0].map((p: GeoJSON.Position) => {
                return p as number[];
            });
        } else if(geojson.type == "MultiPolygon"){
            this.coordinates = geojson.coordinates[0][0].map((p: GeoJSON.Position) => {
                return p as number[];
            });
        } else {
            throw "Invalid GeometryObject";
        }
        
        this.geojson = geojson;
        
        var feature = this.L.geoJSON(geojson);
        this.boundingbox = feature.getBounds();
        this.center = this.boundingbox.getCenter();
    }

    getBounds(): LatLngBounds {
        return this.boundingbox;
    }

    getCenter(): LatLng {
        return this.center;
    }

    getCoordinates(): LatLngLiteral[]{
        return this.coordinates.map((c: number[]) => {
            return { lat: c[1], lng: c[0] } as LatLngLiteral;
        }).reverse();
    }

    getPolygon(): Polygon{
        return new this.L.Polygon(this.getCoordinates());
    }

    getPolygonInverse(): Polygon {
        let polygonCoords: LatLng[] = this.getPolygon().getLatLngs() as LatLng[]
        
        return new this.L.Polygon([this.outerbounds, polygonCoords], {
            //paths: polygonCoords,
            //strokeColor: '#99BD73',
            //strokeOpacity: 0.8,
            stroke: false,
            //fillColor: '#9e9e9e',
            fillOpacity: 0.2,
            //fillOpacity: 0
          });
    }

    private outerbounds = [
        this.L.latLng(85, 180),
        this.L.latLng(85, 90),
        this.L.latLng(85, 0),
        this.L.latLng(85, -90),
        this.L.latLng(85, -180),
        this.L.latLng(0, -180),
        this.L.latLng(-85, -180),
        this.L.latLng(-85, -90),
        this.L.latLng(-85, 0),
        this.L.latLng(-85, 90),
        this.L.latLng(-85, 180),
        this.L.latLng(0, 180),
        this.L.latLng(85, 180)
    ];
}