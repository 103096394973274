import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild, type OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommunityService } from 'src/app/services/community/community.service';
import { Appearance, loadStripe, Stripe, StripeElements, StripePaymentElementOptions } from '@stripe/stripe-js';
import { STRIPE_PUBKEY } from 'src/environments/environment';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom } from 'rxjs';
import { LanguageService } from '../../language.service';
import Swal from 'sweetalert2';
import { PaymentResult } from '../payments.service';
import { SpinnerModule } from 'src/app/components/spinner/spinner.module';
import { SubtotalWithTaxAndFeeDTO } from 'src/app/DTO/prices/SubtotalWithTaxAndFeeDTO';

@Component({
  selector: 'app-stripe-modal',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SpinnerModule
  ],
  templateUrl: './modal-stripe.component.html',
  styleUrls: ['./modal-stripe.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StripeModalComponent implements OnInit {

  @ViewChild('cardInfo') cardInfo: ElementRef;

  public totalPrice: number;
  public corpColor: string;

  public paymentStarted = false;
  public filled = false;

  private clientSecret: string;

  private stripe: Stripe;
  private stripeElements: StripeElements;

  private paymentElement: any;

  public firstPayment: SubtotalWithTaxAndFeeDTO;

  constructor(
    private translate: TranslateService,
    private languageService: LanguageService,
    private communityService: CommunityService,
    private config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef
  ) {

  }

  async ngOnInit(): Promise<void> {
    console.log(this.config.data);
    this.clientSecret = this.config.data.clientSecret;
    this.totalPrice = this.config.data.amount;
    this.firstPayment = this.config.data.firstPayment;

    this.initStripe();
  }

  private async onCardChange({ complete, empty }) {
    this.filled = (complete && !empty);
  }

  private async initStripe() {
    let c = await firstValueFrom(this.communityService.Get());
    this.corpColor = c.color1
    let lang = await firstValueFrom(this.languageService.getCurrentLang());
    this.stripe = await loadStripe(STRIPE_PUBKEY);

    const appearance: Appearance = {
      theme: 'flat',
      variables: { colorPrimary: this.corpColor }
    };

    //Lang Fix for Catalan
    if (lang == 'ca' ) lang = 'es';

    this.stripeElements = this.stripe.elements({
      clientSecret: this.clientSecret,
      locale: lang as any,
      appearance
    });

    const options: StripePaymentElementOptions = {
      layout: {
        type: 'tabs',
        defaultCollapsed: false,
      },
      fields: {
        billingDetails: {
          address: {
            country: 'never'
          }
        }
      }
    };

    this.paymentElement = this.stripeElements.create('payment', options);
    this.paymentElement.mount(this.cardInfo.nativeElement);
    this.paymentElement.addEventListener('change', this.onCardChange.bind(this));
  }

  public checkoutPayment() {
    this.paymentStarted = true;
    try {
      this.stripe.confirmPayment({
          elements: this.stripeElements,
          redirect: 'if_required',
          confirmParams: {
            return_url: '',
            payment_method_data: {
              billing_details: {
                address: {
                  country: 'es'
                }
              }
            }
          },
        }
      )
      .then((stripeResult) => {
        if (stripeResult.error) {
          this.handlePaymentErr(stripeResult.error.message);
        } else {
          if (stripeResult.paymentIntent.status == 'succeeded') {
            this.dialogRef.close(PaymentResult.SUCCESS);
            /*this.ticketsService.checkPayment(this.communityCode, this.ticketPurchaseUuid)
              .subscribe((response) => {
                if (response.result == 'ok') {
                  this.paymentStarted = false;
                  this.ref.close();
                  Swal.fire({
                    title: this.translate.instant('TICKETS.BuySuccess'),
                    html: this.translate.instant('TICKETS.MailConfirmationSent') + `
                    <br>
                    <a target='_blank' href='/${this.communityCode}/tickets/print/${response.ticketPurchaseUuid}?print=true' style='background-color: ${this.corpColor} !important' class='btn download-btn'>
                      <i class="fa-solid fa-file-arrow-down"></i> ${this.translate.instant('TICKETS.Download')}
                    </a>`,
                    icon: 'success',
                    confirmButtonText: this.translate.instant('Accept'),
                  })
                }
              })*/

          } else {
            this.handlePaymentErr("")
          }
        }
      })
      .catch((err) => {
        this.handlePaymentErr(err.message)
      })
    } catch (err) {
      this.handlePaymentErr(err.message);
    }
  }

  private handlePaymentErr(msg: string) {
    this.paymentStarted = false;
    console.error('ERROR AL PAGAR: ', msg);

    Swal.fire({
      title: this.translate.instant('TICKETS.TryAgain'),
      text: this.translate.instant('TICKETS.ErrorOnPay'),
      icon: 'warning',
      confirmButtonText: this.translate.instant('TICKETS.RepeatAgain')
    })
  }
}
