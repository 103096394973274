import { ApiService } from 'src/app/services/api.service';
import { LocationService } from './../../services/location.service';
import { Component, OnInit, ViewChild, ChangeDetectorRef, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { MapboxService } from 'src/app/services/mapbox.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { CityPolygonModel } from 'src/app/DTO/mapbox/CityPolygonModel';
import { GeocodeFeatureDTO } from 'src/app/DTO/mapbox/GeocodeResponseDTO';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LeafletService } from 'src/app/services/leaflet/leaflet.service';
import { CircleMarker, DragEndEvent, LatLng, LeafletEvent, LocationEvent, Map, MapOptions, Marker, Polygon } from 'leaflet';
@Component({
  selector: 'app-location-selector',
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.scss'],
})
export class LocationSelectorComponent implements OnInit, AfterViewInit {

  @Input('marker-circle-radius') MarkerCircleRadius: number;

  @Input('notes') notes: string;
  @Input() coordsPam: any[];
  @Input() showOnlyMap: boolean;
  @Output() onCoordsChange: EventEmitter<LatLng> = new EventEmitter<LatLng>();
  @Output() onAddressChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onMarkerInCityChange: EventEmitter<boolean> = new EventEmitter<boolean>();  

  @ViewChild('addressInput') addressInput: Input;

  @Input() refresh: EventEmitter<any>;

  map: Map;
  //mapOptions: MapOptions;
  //mapLoading = true;

  city: CityPolygonModel;

  address: string;

  locationMarker: Marker;
  locationMarkerCircle: CircleMarker;

  searchStreetTimeout: any;
  searchStreetDisabled: boolean;

  loadingAddress: boolean;
  
  dragIdleTimeout: any;
  coordsDragged = false;
  coordsTouched = false;
  public dragHelper = false;

  isFirstLocating = true;
  isLocating = false;

  communityName: string;

  locationSelected: GeocodeFeatureDTO;

  locationsFound: Array<GeocodeFeatureDTO>;

  subject: Subject<any> = new Subject();

  constructor(
    public ref: ChangeDetectorRef,    
    private mapbox: MapboxService,    
    private location: LocationService,
    private api: ApiService,  
    private activeRoute: ActivatedRoute,
    private translate: TranslateService,
    private leafletService: LeafletService
  ) { }

  ngOnInit() {

    if(this.refresh){
      this.refresh.subscribe(() => {
        this.reloadMap();
      })
    }


    this.isLocating = false;
    this.isFirstLocating = true;    

    this.communityName = this.activeRoute.snapshot.params['community'];  

    this.subject
      .subscribe((query) => {
        console.log('query', query)
        this.searchLocationsBetweenBounds(query);
    })
  }

  reloadMap (){
    this.map.fitBounds(this.city.getBounds(),
      {
        padding: new this.leafletService.L.Point(-20, -20)
      });

    this.removeMarker();
  }

  ngAfterViewInit() {    

    this.api.getCommunityByCode(this.communityName)
      .subscribe((community) => {
        this.api.GetGeoTown(community.id)
          .subscribe((res: any) => {
            if (this.leafletService.L) {
              // Leaflet is loaded - load the map!
              if (res) {
                this.city = new CityPolygonModel(this.leafletService.L, res.geojson);
              }
              this.initMap();
            } else {
              // When the server renders it, it'll show this.
            }
                       
          });
    });
  }

  private initMap() {

    let mapOptions = {
      attributionControl: false,
      center: this.city ? this.city.getCenter() : new this.leafletService.L.LatLng(40.0619708, -2.1655345),
      zoom: this.city ? null : 4,
      dragging: true,
      zoomControl: false,
      layers: [
        this.leafletService.L.tileLayer(
          `https://api.mapbox.com/styles/v1/dfargas/ckle1xk064nir17nu31n2dd0c/tiles/{z}/{x}/{y}?access_token=${environment.MAPBOX_TOKEN}`,
          {
            maxZoom: 20,
            tileSize: 512,
            zoomOffset: -1
          }),
      ],
    } as MapOptions;

    //this.mapLoading = false;

    setTimeout(() => {
      this.map = this.leafletService.L.map('map', mapOptions);
      this.onMapReady(this.map);
    }, 0);
  }

  searchLocationsBetweenBounds(locationName) {
    this.address = locationName;
    this.SearchAddress();
  }

  onMapReady(map: Map) {
    this.map = map;
    //map.addControl(control.zoom({ position: 'bottomright' }));

    if(this.city){
      this.map.fitBounds(this.city.getBounds(), 
      {
        padding: new this.leafletService.L.Point(-20, -20)
      });

      // Construct the polygon.
      const polygon = this.city.getPolygonInverse();
    
      this.map.addLayer(polygon);
    }

    this.map.addEventListener('moveend', () => {
      console.log('moveend');
      //this.blurInput();
    });

    this.map.addEventListener('dragstart', () => {
      console.log('dragstart blurInput');
      this.stopIdleTimeout();
    });

    this.map.addEventListener('dragend', () => {
      //this.startIdleTimeout();
      this.coordsTouched = true;
      this.stopIdleTimeout();
    });
    if(!this.showOnlyMap){
      this.map.addEventListener('click', (e: LocationEvent) => {
        this.placeMarkerAndPanTo(e.latlng);
        this.coordsTouched = true;

        this.stopIdleTimeout();
        this.startIdleTimeout();
      });
    }


    /*
    this.location.getCurrentPosition().then((res)=>{
      let pos = new google.maps.LatLng(res.latitude, res.longitude);

      this.map.setCenter(pos);

      this.locationMarker = new google.maps.Marker({
        map: this.map,
        position: pos,
        draggable:true,
        animation: google.maps.Animation.DROP,
      });

      this.coords = {
        lat: res.latitude,
        lng: res.longitude,
      }
    });
    */

    this.GetGPS();
    if(this.coordsPam){
      for (const cord of this.coordsPam) {
        this.addMarker(cord)
      }
      this.map.fitBounds(this.coordsPam ,{
        padding: [100, 100]
      });


    }
  }

  checkMarkerInsideCity(LatLng: LatLng){
    if(this.city){
      const isMarkerInCity = this.isPointInsidePolygon(LatLng, this.city.getPolygon());
      this.onMarkerInCityChange.emit(isMarkerInCity);
      return isMarkerInCity;
    } else {
      this.onMarkerInCityChange.emit(true);
      return true;
    }
  }

  isPointInsidePolygon(point: LatLng, poly: Polygon) {
    const polyPoints = (poly.getLatLngs() as LatLng[][])[0];       
    const x = point.lat, y = point.lng;

    let inside = false;
    for (let i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
      const xi = polyPoints[i].lat, yi = polyPoints[i].lng;
      const xj = polyPoints[j].lat, yj = polyPoints[j].lng;

      const intersect = ((yi > y) !== (yj > y))
          && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
    }

    return inside;
}

  onAddressInputChange(){

    if (!this.searchStreetDisabled){
      this.onCoordsChange.emit(null);
    }

    if (this.searchStreetTimeout){
      clearTimeout(this.searchStreetTimeout);
    }

    this.searchStreetTimeout = setTimeout(() => {
      if (this.searchStreetDisabled){
        this.searchStreetDisabled = false;
      }else{
        this.SearchAddress();
      }
    }, 1500);
  }

  onComplete(event) {
    if(event.query.length > 3) {
      this.subject.next(event.query);
    } else {
      this.locationsFound= [];
    }    
  }

  onAddressEnter(){
    
    this.onCoordsChange.emit(null);

    if (this.searchStreetTimeout){
      clearTimeout(this.searchStreetTimeout);
    }

    this.SearchAddress();
  }

  SearchAddress() {    

    if (!this.address) {
      this.onCoordsChange.emit(null);
      this.onAddressChange.emit(this.address);
      return;
    }

    this.loadingAddress = true;

    this.mapbox.Geocode(this.address, this.city?.getBounds())
    .then((data: any) => {
      console.log(data);
      this.loadingAddress = false;
      
      if (data.features.length) {
        console.log('Geocode blurInput');      
        
        this.locationsFound = data.features;

        console.log('locations', this.locationsFound);

        console.log(data.features); 

      } else {        
        this.locationsFound = [];        
      }
    });
  }

  onSelectedLocation(event: any) {
    const result = event.value;

    this.address = result.address;

    this.SearchAddress();

    this.placeMarkerAndPanTo(this.leafletService.L.latLng(result.geometry.coordinates[1], result.geometry.coordinates[0]));

    this.startIdleTimeout();
  }

  placeMarkerAndPanTo(location: LatLng) {
    
    this.map.flyTo(location, 16, { duration: 1 });

    this.removeMarker();

    this.addMarker(location);

    if (this.MarkerCircleRadius){
      this.addMarkerCircle(location);
    }

    this.changeCoords(location);
  }

  addMarker(location: LatLng){
    this.locationMarker = this.leafletService.L.marker(location, {
      draggable: true,
      riseOnHover: true,
      autoPan: true,
      autoPanPadding: [20, 20],
      icon: this.leafletService.L.icon({
        iconSize: [ 38, 38 ],
        iconAnchor: [ 19, 38],
        iconUrl: 'assets/icons/markers/marker_move.svg',
        shadowUrl: 'assets/icons/markers/marker_shadow.png',
        shadowAnchor: [13, 40],
        //className: 'animated-icon' 
      })
    });

    this.locationMarker.addEventListener('dragstart', (e: LeafletEvent) => {
      this.coordsDragged = true;
      this.coordsTouched = true;
      this.stopIdleTimeout();
    });

    this.locationMarker.addEventListener('drag', (e: LeafletEvent) => {
      if (this.locationMarkerCircle){
        this.locationMarkerCircle.setLatLng(this.locationMarker.getLatLng());
      }
    });

    this.locationMarker.addEventListener('dragend', (e: DragEndEvent) => {
      const pos = this.locationMarker.getLatLng();
      this.changeCoords(pos);
    });

    this.map.addLayer(this.locationMarker);
  }

  addMarkerCircle(location: LatLng){
    this.locationMarkerCircle = this.leafletService.L.circle(location, {
      radius: this.MarkerCircleRadius,
      stroke: false
      });

    this.map.addLayer(this.locationMarkerCircle);
  }

  removeMarker(){
    if (this.locationMarker) {
      this.locationMarker.remove();
    }

    if (this.locationMarkerCircle){
      this.locationMarkerCircle.remove();
    }

    this.stopIdleTimeout();
  }
  
  changeCoords(location: LatLng){
    const coords = location;
    this.onCoordsChange.emit(coords);
    this.checkMarkerInsideCity(location);
  }
  
  startIdleTimeout(){
    this.dragIdleTimeout = setTimeout(() => {
      this.dragHelper = true;
      this.ref.detectChanges();
    }, 2000);
  }

  stopIdleTimeout(){
    this.dragHelper = false;
    this.ref.detectChanges();

    if(this.dragIdleTimeout){
      clearTimeout(this.dragIdleTimeout);
    }
  }

  GetGPS() {
    setTimeout(() => {
      this.isLocating = true;
    }, 0);

    
    this.location.getLocation(!this.isFirstLocating).then((res) => {
      const latlng = new this.leafletService.L.LatLng(res.coords.latitude, res.coords.longitude);

      if (this.checkMarkerInsideCity(latlng)){
        this.placeMarkerAndPanTo(latlng);
        this.startIdleTimeout();
      }else if (!this.isFirstLocating){
        this.removeMarker();
        this.presentAlert('Sembla que no et trobes al teu municipi');        
      }
    }).finally(() => {

      setTimeout(() => {
        this.isLocating = false;
        this.isFirstLocating = false;
      }, 0);

    });
  }


  async presentAlert(result: string) {
    Swal.fire({
      title: 'Oops!',
      text: result,
      icon: 'warning',
      confirmButtonText: 'Acceptar',
    });    

  }

}
