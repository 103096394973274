export class PeriodScheduleDTO {
  id: number;
  name: string;
  start: string;
  end: string;
  startTypeDate: Date;
  endtypeDate: Date;
  maxDate: Date = null;
  minDate: Date = null;
  scheduleDayOfWeeks: ScheduleDayOfWeekDTO[];
  errorDateStartBigger: boolean = false;
  errorDateEndLower: boolean = false;
  errorEmptyName: boolean = false;
  errorEmptyStart: boolean = false;
  errorEmptyEnd: boolean = false;
}

export class ScheduleDayOfWeekDTO {
  id: number;
  periodScheduleId: number;
  periodScheduleName: string;
  periodScheduleStart: string;
  periodScheduleEnd: string;
  periodScheduleStartDate: Date;
  periodScheduleEndDate: Date;
  dayOfWeek: number;
  name: string;
  minlabel: string;
  isActive: boolean = true;
  scheduleHours: ScheduleHourDTO[];
}

export class ScheduleHourDTO {
  id: number;
  openHourDate: Date;
  finishHourDate: Date;
  finishHour: number = 13;
  finishMinuts: number = 30;
  openHour: number = 8;
  openMinuts: number = 30;
  hasErrors: boolean = false;
  errorMessage: string;
  overlapError: boolean = false;

}
export class FindReservationDTO{
  codeIdentifier: string;
  reservationDate: string;

}
