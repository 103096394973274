<div class="reservation-detail-wrapper">
    <div class="container">
        <form [formGroup]="form" #reservationSearch="ngForm" id="reservationSearch" class="mt-5">
            <h2 class="fw-600 mb-3">{{ 'PRIORAPPOINTMENTDETAIL.searchAppointmentTitle' | translate }}</h2>

            <div class="row">
                <div class="col-12 col-md-5 mb-3">
                    <label class="mb-2 font-w-600"
                           [ngClass]="form.get('code')?.hasError('required') && myForm?.submitted ? 'text-danger' : ''">
                        {{ 'PRIORAPPOINTMENTDETAIL.reservationCodeLabel' | translate }}
                    </label>
                    <br>
                    <input type="text" class="w-100" pInputText [(ngModel)]="codeSearch" id="name" formControlName="code" />
                    <p class="text-danger mt-1" *ngIf="form.get('code')?.hasError('required') && myForm?.submitted">
                        {{ 'PRIORAPPOINTMENTDETAIL.reservationCodeError' | translate }}
                    </p>
                </div>

                <div class="col-12 col-md-5 mb-3">
                    <label class="mb-2 font-w-600"
                           [ngClass]="form.get('date')?.hasError('required') && myForm?.submitted ? 'text-danger' : ''">
                        {{ 'PRIORAPPOINTMENTDETAIL.reservationDateLabel' | translate }}
                    </label>
                    <br>
                    <p-calendar class="w-100" [(ngModel)]="dateSearch" id="date" formControlName="date" />

                    <p class="text-danger mt-1" *ngIf="form.get('date')?.hasError('required')  && myForm?.submitted">
                        {{ 'PRIORAPPOINTMENTDETAIL.reservationDateError' | translate }}
                    </p>
                </div>
                <div class="col-12 col-md-2 mb-3 d-flex align-items-end flex-column">
                    <label class="mb-2 font-w-600">
                    </label>
                    <br>
                    <button class="btn bg-white me-2 rounded border"
                            style="padding:0.75rem 1.5rem" (click)="search()"
                            type="submit" form="reservationSearch">
                        <span><i class="fa-solid fa-search me-2"></i>{{ 'PRIORAPPOINTMENTDETAIL.searchButton' | translate }}</span>
                    </button>
                    <p></p>
                </div>
            </div>
        </form>

        <p-skeleton *ngIf="isLoading" styleClass="mb-2" height="120px"></p-skeleton>
        <div *ngIf="tryFind">
            <div class="alert alert-secondary" role="alert">
                <span>{{ 'PRIORAPPOINTMENTDETAIL.noReservationFound' | translate }}</span>
            </div>
        </div>
        <app-finish-reservation *ngIf="reservation"
                                [itsUpdating]="true"
                                [community]="community"
                                [reservation]="reservation">
        </app-finish-reservation>
    </div>
</div>
