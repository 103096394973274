<div class="step-2-wrapper">
    <div class="banner-resum border rounded p-3"  [style.background-color]="community?.color1 ? _themeService.getColorWithOpacity(community.color1, 0.2) : 'white'">
        <h2 class="fw-600">{{ 'STEP2.summary' | translate }}</h2>
        <div class="row">
            <div class="col-12 col-md-6 mb-2"><span class="fw-600 me-1">{{ 'STEP2.center' | translate }}: </span> <span> {{reservation.centerName}}</span> </div>
            <div class="col-12 col-md-6 mb-2"><span class="fw-600 me-1">{{ 'STEP2.type' | translate }}: </span> <span> {{reservation.type === 0 ? ('STEP2.inPerson' | translate) : ('STEP2.online' | translate)}}</span> </div>
            <div class="col-12 col-md-6 mb-2"><span class="fw-600 me-1">{{ 'STEP2.service' | translate }}:</span>  <span> {{reservation.serviceName}}</span> </div>
            <div class="col-12 col-md-6 mb-2"><span class="fw-600 me-1">{{ 'PRIORAPPOINTMENTFINISH.date' | translate }}: </span> <span> {{reservation.startTime | date: "dd/MM/yy"}}</span> </div>
            <div class="col-12 col-md-6 mb-2"><span class="fw-600 me-1">{{ 'PRIORAPPOINTMENTFINISH.hour' | translate }}: </span> <span> {{reservation.startTime | date: "hh:mm"}}</span> </div>
        </div>
    </div>
    <form [formGroup]="form" class="mt-5">
        <h2 class="fw-600 mb-3">{{ 'STEP2.personalData' | translate }}</h2>

        <div class="row">
            <div class="col-12 col-md-6 mb-3">
                <label class="mb-2 font-w-600"
                       [ngClass]="form.controls['name'].invalid && form.controls['name'].touched ? 'text-danger' : ''">
                    {{ 'STEP2.name' | translate }} *
                </label>
                <br>
                <input type="text" class="w-100" pInputText [(ngModel)]="reservation.name" id="name" formControlName="name" />
                <p class="text-danger" *ngIf="form.controls['name'].invalid && form.controls['name'].touched">
                    {{ 'STEP2.fieldRequired' | translate }}
                </p>
            </div>

            <div class="col-12 col-md-6 mb-3">
                <label class="mb-2 font-w-600"
                       [ngClass]="form.controls['surname'].invalid && form.controls['surname'].touched ? 'text-danger' : ''">
                    {{ 'STEP2.surname' | translate }} *
                </label>
                <br>
                <input type="text" class="w-100" pInputText [(ngModel)]="reservation.surname" id="surname" formControlName="surname" />
                <p class="text-danger" *ngIf="form.controls['surname'].invalid && form.controls['surname'].touched">
                    {{ 'STEP2.fieldRequired' | translate }}
                </p>
            </div>

            <div class="col-12 col-md-6 mb-3">
                <label class="mb-2 font-w-600"
                       [ngClass]="form.controls['phone'].invalid && form.controls['phone'].touched ? 'text-danger' : ''">
                    {{ 'STEP2.phone' | translate }} *
                </label>
                <br>
                <input type="text" class="w-100" pInputText [(ngModel)]="reservation.phone" id="phone" formControlName="phone" />
                <p class="text-danger" *ngIf="form.controls['phone'].invalid && form.controls['phone'].touched">
                    {{ 'STEP2.fieldRequired' | translate }}
                </p>
            </div>

            <div class="col-12 col-md-6 mb-3">
                <label class="mb-2 font-w-600"
                       [ngClass]="form.controls['email'].invalid && form.controls['email'].touched ? 'text-danger' : ''">
                    {{ 'STEP2.email' | translate }} *
                </label>
                <br>
                <input type="email" class="w-100" pInputText [(ngModel)]="reservation.mail" id="email" formControlName="email" />
                <p class="text-danger" *ngIf="form.controls['email'].invalid && form.controls['email'].touched">
                    {{ 'STEP2.fieldRequired' | translate }}
                </p>
            </div>

            <div class="col-12 col-md-6 mb-3">
                <label class="mb-2 font-w-600"
                       [ngClass]="form.controls['documentType'].invalid && form.controls['documentType'].touched ? 'text-danger' : ''">{{ 'STEP2.documentType' | translate }} *</label>
                <br>
                <p-dropdown
                        [options]="documentTypes"
                        [(ngModel)]="reservation.documentType"
                        id="documentType"
                        label="name"
                        value="value"
                        formControlName="documentType">
                </p-dropdown>
                <p class="text-danger" *ngIf="form.controls['documentType'].invalid && form.controls['documentType'].touched">
                    {{ 'STEP2.fieldRequired' | translate }}
                </p>
            </div>

            <div class="col-12 col-md-6 mb-3">
                <label class="mb-2 font-w-600"
                       [ngClass]="form.controls['documentNumber'].invalid && form.controls['documentNumber'].touched ? 'text-danger' : ''">
                    {{ 'STEP2.documentNumber' | translate }} *
                </label>
                <br>
                <input type="text" class="w-100" pInputText [(ngModel)]="reservation.numberDocumentation" id="documentNumber" formControlName="documentNumber" />
                <p class="text-danger" *ngIf="form.controls['documentNumber'].hasError('required') && form.controls['documentNumber'].touched">
                    {{ 'STEP2.fieldRequired' | translate }}
                </p>
                <p class="text-danger" *ngIf="form.controls['documentNumber'].hasError('pattern') && form.controls['documentNumber'].touched">
                    {{'PRIORAPPOINTMENT.documentInvalid' | translate}}
                </p>
            </div>

            <div class="col-12 col-md-6 mb-3">
                <label class="mb-2 font-w-600">{{ 'STEP2.observations' | translate }}</label>
                <br>
                <textarea class="w-100" pInputTextarea [(ngModel)]="reservation.observations" id="observations" formControlName="observations">
                </textarea>
            </div>
            <div class="col-12 mb-4">
                <div class="form-check">
                    <input
                            type="checkbox"
                            class="form-check-input"
                            id="acceptTractament"
                            formControlName="acceptTractament"
                            [(ngModel)]="reservation.acceptTractament"
                    />
                    <label
                            for="acceptTractament"
                            class="form-check-label"
                    >
                        {{ 'STEP2.consent' | translate }}
                    </label>
                </div>
                <p class="text-danger" *ngIf="form.controls['acceptTractament'].invalid && form.controls['acceptTractament'].touched">
                    {{ 'STEP2.acceptConsent' | translate }}
                </p>
            </div>
        </div>
    </form>
</div>
