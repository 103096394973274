<ng-container *ngIf="customField && customField.publicPermissions == 'rw'">
  <div class="d-flex flex-column">
    <label>{{customField.name }} <span *ngIf="customField.required" class="required_asterisk">*</span></label>

    <ng-container *ngIf="customField.type == InputTypes.TEXT || customField.type == InputTypes.DNI">
      <input type="text" pInputText [ngModel]="internalValue" (ngModelChange)="updateChanges($event)"/>
    </ng-container>

    <ng-container *ngIf="customField.type == InputTypes.CHECKBOX">
      <p-inputSwitch [ngModel]="internalValue" (ngModelChange)="updateChanges($event)"></p-inputSwitch>
    </ng-container>

    <ng-container *ngIf="customField.type == InputTypes.TEXTAREA">
      <textarea pInputTextarea [ngModel]="internalValue" (ngModelChange)="updateChanges($event)"></textarea>
    </ng-container>

    <ng-container *ngIf="customField.type == InputTypes.NUMERIC">
      <p-inputNumber [minFractionDigits]="0"
                     [maxFractionDigits]="0"
                     [useGrouping]="false"
                     class="w-100"
                     [ngModel]="internalValue" (ngModelChange)="updateChanges($event)"
                     > </p-inputNumber>
    </ng-container>

    <ng-container *ngIf="customField.type == InputTypes.IBAN">
      <p-inputMask
        mask="aa99 9999 9999 99 9999999999"
        [ngModel]="internalValue" (ngModelChange)="updateChanges($event)"
        placeholder="ES00 0000 0000 00 0000000000"
      />
    </ng-container>


    <ng-container *ngIf="customField.type == InputTypes.DATE || customField.type == InputTypes.DATETIME">
      <p-calendar
        class="size-s"
        [showIcon]="true"
        dateFormat="dd/mm/yy"
        [(ngModel)]="internalValue"
        (ngModelChange)="updateChanges($event)"
        [showTime]="customField.type == InputTypes.DATETIME"
        hourFormat="24"
      ></p-calendar>
    </ng-container>

    <ng-container *ngIf="customField.type == InputTypes.DROPDOWN && customField.dropdownType == DropdownTypes.CHIPS">
      <p-dropdown *ngIf="customField.dropdownOptionsArrayShow"
        [placeholder]="'SelectOptionPlaceholder' | translate"
        [options]="customField.dropdownOptionsArrayShow"
        [(ngModel)]="internalValue"
        [filter]="true"
        (ngModelChange)="updateChanges($event)"
        [showClear]="!customField.required"
        appendTo="body"
        [overlayOptions]="{
          baseZIndex: 1055
        }"
      ></p-dropdown>
    </ng-container>

    <ng-container *ngIf="customField.type == InputTypes.DROPDOWN && customField.dropdownType == DropdownTypes.TABLE">
      <p-dropdown [options]="customField.dropdownOptionsTable"
        [ngModelOptions]="{standalone: true}"
        [(ngModel)]="customField.value"
        optionValue="name"
        optionLabel="name"
        [filter]="true"
        [showClear]="true"
        [placeholder]="'SelectOptionPlaceholder' | translate"
        (ngModelChange)="updateChanges($event);showThePrice($event)">
        <ng-template let-selected pTemplate="selectedItem">
          <div class="flex align-items-center gap-2">
            <div>{{ selected.name }} <b *ngIf="selected.price">+{{ selected.price | currency: 'EUR':'symbol':'1.0-2'}}</b></div>
          </div>
        </ng-template>
        <ng-template let-x pTemplate="item">
          <div class="flex align-items-center gap-2">
            <div>{{ x.name }} <b *ngIf="x.price">+{{ x.price | currency: 'EUR':'symbol':'1.0-2'}}</b></div>
          </div>
        </ng-template>
      </p-dropdown>
    </ng-container>

    <ng-container *ngIf="customField.type === InputTypes.FILE">
      <app-file-uploader
        [category]="'inscriptions'"
        [communityNameInput]=""
        [maxFiles]="1"
        [type]="1"
        [(ngModel)]="files"
        (ngModelChange)="getFile($event)">
      </app-file-uploader>
    </ng-container>

    <small [innerHTML]=" customField.description"></small>
  </div>
</ng-container>
