import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Appearance, loadStripe, StripeElements } from '@stripe/stripe-js';
import { PurchaseResultDTO, StripeData } from 'src/app/DTO/tickets/PurchaseResultDTO';
import { API_URL, STRIPE_PUBKEY } from 'src/environments/environment';
import { CommunityService } from '../community/community.service';
import { firstValueFrom, Observable } from 'rxjs';
import { LanguageService } from '../language.service';
import { StripePaymentComponent } from 'src/app/pages/events/events-detail/payment-modal/stripe-payment/stripe-payment.component';
import { DialogService } from 'primeng/dynamicdialog';
import { StripeModalComponent } from './modal-stripe/modal-stripe.component';
import { ApiService } from '../api.service';
import { RedsysModalComponent } from './modal-redsys/modal-redsys.component';
import { SubtotalWithTaxAndFeeDTO } from 'src/app/DTO/prices/SubtotalWithTaxAndFeeDTO';

export enum PaymentResult{
  SUCCESS,
  FAILED,
  CANCEL
}

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  //private stripe: any;
  //private stripeElements: StripeElements;

  constructor(
    private translate: TranslateService,
    private communityService: CommunityService,
    private languageService: LanguageService,
    private dialogService: DialogService,
    private api: ApiService
  ) { }


  public async processPurchaseResult(res: PurchaseResultDTO, firstPayment: SubtotalWithTaxAndFeeDTO): Promise<PaymentResult>{
    if (res?.status === 'PAID') {
      //this.isPurchaseLoading = false;
      //this.reload();
      return PaymentResult.SUCCESS;
    } else if (res?.status === 'PENDING'){
      if (res?.method === 'STRIPE') {
        return await this.startStripePayment(res, firstPayment);
      } else if (res?.method === 'REDSYS') {
        //this.isPurchaseLoading = false;
        //let redsysData = res?.redsysData;
        return await this.startRedsysPayment(res);
      } else {
        //this.failedMessage();
        return PaymentResult.FAILED;
      }
    } else {
      //this.failedMessage();
      return PaymentResult.FAILED;
    }
  }

  private async startStripePayment(data: PurchaseResultDTO, firstPayment: SubtotalWithTaxAndFeeDTO): Promise<PaymentResult>{
    //let stripe = await loadStripe(STRIPE_PUBKEY);
    //this.paymentLoaded = true;
    //let clientSecret = data.stripeData.paymentIntent;
    //this.ticketPurchaseUuid = res.purchaseUuid;

    //var width = this.isMobile ? '90%' : '700px'

    let ref = this.dialogService.open(StripeModalComponent,
    {
      header: this.translate.instant('PAYMENT.Payment'),
      closable: true,
      position: 'center',
      //styleClass: 'header-transparent',
      //baseZIndex: 1005,
      width: '700px',

      data: {
        amount: data.totalAmount,
        clientSecret: data.stripeData.paymentIntent,
        firstPayment: firstPayment
      }
    });

    let res = await firstValueFrom(ref.onClose)

    if (res == PaymentResult.SUCCESS){

      let res2 = await this.checkPaymentStatus(data.purchaseUuid);

      if (res2.status == 'PAID') {
        return PaymentResult.SUCCESS
      } else {
        return PaymentResult.FAILED;
      }
    } else {
      return PaymentResult.CANCEL;
    }
  }

  private async checkPaymentStatus(uuid: string): Promise<any> {
    let c = await firstValueFrom(this.communityService.Get());
    return firstValueFrom(this.api.get(`${API_URL}/v2/Communities/${c.code}/Payments/${uuid}/check-payment-status`));
  }

  private async startRedsysPayment(data: PurchaseResultDTO): Promise<any> {

    let ref = this.dialogService.open(RedsysModalComponent,
    {
      header: this.translate.instant('PAYMENT.Payment'),
      closable: true,
      position: 'center',
      styleClass: 'redsys',
      //baseZIndex: 1005,
      width: '700px',

      data: {
        orderId: data.redsysData.orderId,
        purchaseUuid: data.purchaseUuid,
      }
    });

    return await firstValueFrom(ref.onClose);
  }

  /*
  private checkoutPayment() {
    //this.paymentStarted = true; TODO
    try {
      this.stripe.confirmPayment({
          elements: this.stripeElements,
          redirect: 'if_required',
          confirmParams: {
            return_url: '',
            payment_method_data: {
              billing_details: {
                address: {
                  country: 'es'
                }
              }
            }
          },
        }
      )
      .then((stripeResult) => {
        if(stripeResult.error) {
          this.handlePaymentErr(stripeResult.error.message);
        } else {
          if (stripeResult.paymentIntent.status == 'succeeded') {
            this.ticketsService.checkPayment(this.communityCode, this.ticketPurchaseUuid)
              .subscribe((response) => {
                if (response.result == 'ok') {
                  this.paymentStarted = false;
                  this.ref.close();
                  Swal.fire({
                    title: this.translate.instant('TICKETS.BuySuccess'),
                    html: this.translate.instant('TICKETS.MailConfirmationSent') + `
                    <br>
                    <a target='_blank' href='/${this.communityCode}/tickets/print/${response.ticketPurchaseUuid}?print=true' style='background-color: ${this.corpColor} !important' class='btn download-btn'>
                      <i class="fa-solid fa-file-arrow-down"></i> ${this.translate.instant('TICKETS.Download')}
                    </a>`,
                    icon: 'success',
                    confirmButtonText: this.translate.instant('Accept'),
                  })
                }
              })
          }
        }
      })
      .catch((err) => {
        //this.handlePaymentErr(err.message)
      })
    } catch (err) {
      //this.handlePaymentErr(err.message);
    }
  }
  */
  /*
  private handlePaymentErr(msg: string) {
    this.paymentStarted = false;
    console.error('ERROR AL PAGAR: ', msg);
    Swal.fire({
      title: this.translate.instant('TICKETS.TryAgain'),
      text: this.translate.instant('TICKETS.ErrorOnPay'),
      icon: 'warning',
      confirmButtonText: this.translate.instant('TICKETS.RepeatAgain')
    })
  }
*/
  /*
  private async stripePayment(stripeData: any, checkPaymentEndpoint: () => any): Promise<PaymentResult>{

    if (!Capacitor.isNativePlatform()){
      Stripe.addListener(PaymentSheetEventsEnum.Loaded, () => {
        //this.isPurchaseLoading = false;
      });
    }

    await Stripe.createPaymentSheet({
      paymentIntentClientSecret: stripeData.paymentIntent,
      customerId: stripeData.customerId,
      customerEphemeralKeySecret: stripeData.ephemeralKey,
      merchantDisplayName: 'Wecoo',
      withZipCode: false
    });

    var result = await Stripe.presentPaymentSheet();

    if (result.paymentResult === PaymentSheetEventsEnum.Completed) {
      //this.isPurchaseLoading = true;

      try {
        let res = await checkPaymentEndpoint();

        //this.isPurchaseLoading = false;

        if (res.result == 'ok'){
          //this.reload();
          //this.purchaseSuccessMessage();
          return PaymentResult.SUCCESS;
        } else {
          //this.purchaseFailedMessage(res.error.message);
          return PaymentResult.FAILED;
        }
      } catch (error) {
        //this.purchaseFailedMessage();
        return PaymentResult.FAILED;
      }

    } else {
      //this.purchaseFailedMessage();
      return PaymentResult.FAILED;
    }

  }

  private async redsysPayment(purchaseUuid: string, fuc: string, terminal: string, orderId: string): Promise<PaymentResult> {

    let connection = new HubConnectionBuilder()
    .withUrl(API_URL + '/hubs/payments', {
      withCredentials: false
    })
    .build();

     // Modal
     const modal = await this.modalCtrl.create({
      component: RedsysModalComponent,
      componentProps: {
        fuc,
        terminal,
        orderId,
        purchaseUuid,
        //ticketId: this.ticketId
      }
    });

    // On payment result
    connection.on("payment-result", (res: PurchaseResultDTO ) => {
      if (res.status == PaymentStatus.PAID){
        modal.dismiss(null, 'ok');
        //this.reload();
        //this.purchaseSuccessMessage();
      } else if (res.status == PaymentStatus.ERROR){
        modal.dismiss(null, 'error');
        //this.purchaseFailedMessage(res.error?.message);
      } else {
        console.warn("payment-result: " + res.status);
      }
    });

    // Connection stat
    connection.start()
    .then(_ => {
      console.log('Connection Started');
      connection.invoke('WaitForPayment', purchaseUuid);

    }).catch(error => {
      console.error(error);
    });

    // Modal present

    modal.present();

    const { role } = await modal.onWillDismiss();

    connection.stop();


    if (role === 'ok') {
      return PaymentResult.SUCCESS;
    } else if (role === 'error'){
      return PaymentResult.FAILED;
    } else {
      return PaymentResult.CANCEL;
    }
  }
  */
}
